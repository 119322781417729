import { useEffect, useState } from "react";
import useWindowSize from "../components/useWindowSize";
import { GetShopByID } from "../data/ShopController";
import { adminUrl } from "../components/urls";

export const Parallaxbanner = () => {
  const { isMobile } = useWindowSize();
  const[ShopInfos, setShopInfos] = useState([])  
  const styles = createStyles({ isMobile, ShopInfos });   

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID(); 
        setShopInfos(data)  
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);  

  return (
    <div style={styles.mainDiv}>
      <div className="container" style={styles.container}>
        <div className="row" style={{fontSize:'24px'}}>
          <div className="col">
            <div className="banner-contain" style={{fontSize:'24px'}}>
              {/* <h1>{ShopInfos.DownLogo}</h1> */}
              {/* <h3>.</h3>
              <h4>.</h4> */}
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
};

const createStyles = ({ isMobile, ShopInfos }) => ({ 
  mainDiv: {
    backgroundColor: "black",
    backgroundImage: `url('${adminUrl}/backend/${ShopInfos.DownLogo}')`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundSize:  isMobile ? 'contain': "cover",
    padding: isMobile ? "140px 0px 140px 0px" : "240px 50% 0 0px",
    height: isMobile ?   '40vh' : "100vh",
    marginTop: "1%",
  },

  container: {
    marginTop: "10%",
    color: "red",
    fontSize:'24px',
    //   opacity: "0.6",
      height: isMobile ? "50px" : "25%"
  },
});
