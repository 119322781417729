import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { GetFilteredProducts } from "../data/productController";
import { CurrencyConverter } from "../components/Currency";
import { adminUrl } from "../components/urls";
import { QuickViewModal } from "../components/QuickViewModal";
import useShopNow from "../components/ShopNow";
import {
  AddWishlist,
  DeleteWishlist,
  GetWishlist,
} from "../data/wishlistController";
import { ContactOnWP } from "../components/whatsapp";
import { useNavigate } from "react-router-dom";
import { GetShopByID } from "../data/ShopController";

export const CategoriesSection = (isLogedIn) => {
  const TopMenu = JSON.parse(localStorage.getItem("TopMenu"));
  const Categories = JSON.parse(localStorage.getItem("Category"));
  const Brands = JSON.parse(localStorage.getItem("Brand"));
  const shopNow = useShopNow();
  const [products, setProducts] = useState([]);

  const repeatedProducts = Array(1).fill(products).flat();
  const [productsParPage, setProductsParPage] = useState(24);
  const [curentPageNumber, setCurentPageNumber] = useState(1);
  const [isLowTohight, setIsLowTohight] = useState(true);

  const [pageNumbers, setPageNumbers] = useState([]);

  const startProduct = (curentPageNumber - 1) * productsParPage + 1;
  const endProduct = Math.min(
    curentPageNumber * productsParPage,
    repeatedProducts.length
  );

  const [Wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();

       
  const [ShopInfo, setShopInfo] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfo(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);

  const handelAddWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      const updatedWishlist = [...(Wishlist || []), product];
      setWishlist(updatedWishlist);
      AddWishlist(product);
    } else {
      navigate("/Login");
    }
  };
  const handelRemoveWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      DeleteWishlist(product);
      const updatedWishlist = Wishlist.filter(
        (item) => item.ProductID !== product.ProductID
      );
      setWishlist(updatedWishlist);
    } else {
      navigate("/Login");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);

  useEffect(() => {
    const totalPages = Math.ceil(repeatedProducts.length / productsParPage);
    setPageNumbers(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [repeatedProducts.length, productsParPage]);

  const productParPage = async (e) => {
    setProductsParPage(e);
    setCurentPageNumber(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      const index = {
        TopMenu: TopMenu
          ? TopMenu.GWearID
          : Categories
          ? Categories.GWearID
          : "",
        Categories: Categories && Categories.SCategoryID,
        Brands: Brands && Brands.BrandID,
      };
      try {
        const data = await GetFilteredProducts(index);
        setProducts(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, [TopMenu, Brands, Categories]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openQuickView = async (product) => {
    await setSelectedProduct(product);
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && (
        <QuickViewModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false) + setSelectedProduct()}
        />
      )}

      <div className="collection-wrapper">
        <div className="container">
          <div className="row">
            <div className="collection-content col">
              <div className="page-main-content">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="top-banner-wrapper">
                      {/* <a href="#">
                      <img
                        src="/src/assets/images/mega-menu/2.jpg"
                        className="img-fluid blur-up lazyloaded"
                        alt=""
                      />
                    </a> */}
                      <div className="top-banner-content small-section">
                        <h4>
                          {" "}
                          {TopMenu
                            ? TopMenu.GDesription
                            : Categories
                            ? Categories.GDesription +
                              " / " +
                              Categories.SCategory
                            : Brands
                            ? Brands.Brand
                            : ""}
                        </h4>
                        <p></p>
                      </div>
                    </div>
                    <div className="collection-product-wrapper">
                      <div className="product-top-filter">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="col-12">
                              <div className="product-filter-content">
                                <div className="collection-view">
                                  <ul>
                                    <li>
                                      <i className="fa fa-th grid-layout-view"></i>
                                    </li>
                                    <li>
                                      <i className="fa fa-list-ul list-layout-view"></i>
                                    </li>
                                  </ul>
                                </div>
                                <div className="collection-grid-view">
                                  <ul>
                                    <li>
                                      <img
                                        src="../assets/images/icon/2.png"
                                        alt=""
                                        className="product-2-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/3.png"
                                        alt=""
                                        className="product-3-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/4.png"
                                        alt=""
                                        className="product-4-layout-view"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src="../assets/images/icon/6.png"
                                        alt=""
                                        className="product-6-layout-view"
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-page-per-view">
                                  <select
                                    defaultValue={productsParPage}
                                    onChange={(e) =>
                                      productParPage(e.target.value)
                                    }
                                  >
                                    <option value="24">
                                      24 Products Par Page
                                    </option>
                                    <option value="50">
                                      50 Products Par Page
                                    </option>
                                    <option value="100">
                                      100 Products Par Page
                                    </option>
                                  </select>
                                </div>
                                <div className="product-page-filter">
                                  <select
                                    defaultValue={true}
                                    onChange={(e) =>
                                      setIsLowTohight(e.target.value === "true")
                                    }
                                  >
                                    <option value="true">Low to high</option>
                                    <option value="false">High to low</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Loop */}
                      <div className="product-wrapper-grid">
                        <div className="row margin-res">
                          {products.length > 0
                            ? repeatedProducts
                                .sort((a, b) =>
                                  isLowTohight
                                    ? a.Price - b.Price
                                    : b.Price - a.Price
                                )
                                .slice(
                                  (curentPageNumber - 1) * productsParPage,
                                  productsParPage * curentPageNumber
                                )
                                .map((product, index) => {
                                  // const ProductDetailstruncatedHtml =
                                  //   truncateText(product.ProductDetails, 250);

                                  return (
                                    <div
                                      className="col-xl-3 col-6 col-grid-box"
                                      key={index}
                                    >
                                      <div className="product-box">
                                        <div className="img-wrapper">
                                          <div className="front">
                                            <a
                                              href="product-page(no-sidebar).html"
                                              className="bg-size blur-up lazyloaded  "
                                              style={{
                                                backgroundImage: `url("${adminUrl}/backend/${product.Photo}")`,
                                                backgroundSize: "contain",
                                                backgroundPosition:
                                                  "center center",
                                                backgroundRepeat: "no-repeat",
                                                display: "block",
                                                height: "260px",
                                                borderRadius: " 10px",
                                                border: "1px solid #eeebeb",
                                              }}
                                              onClick={() => shopNow(product)}
                                            >
                                              <img
                                                src={
                                                  adminUrl +
                                                  "/backend/" +
                                                  product.Photo
                                                }
                                                className="img-fluid blur-up lazyload bg-img"
                                                alt=""
                                                style={{ display: "none" }}
                                              />
                                            </a>
                                          </div>
                                          <div className="back">
                                            <a
                                              href="#"
                                              className="bg-size blur-up lazyloaded"
                                              style={{
                                                backgroundImage: `url(" ${adminUrl}/backend/${product.Photo} ")`,
                                                backgroundSize: "contain",
                                                backgroundPosition:
                                                  "center center",
                                                backgroundRepeat: "no-repeat",
                                                display: "block",
                                                height: "260px",
                                                borderRadius: " 10px",
                                                border: "1px solid #eeebeb",
                                              }}
                                              onClick={() => shopNow(product)}
                                            >
                                              <img
                                                src={
                                                  adminUrl +
                                                  "/backend/" +
                                                  product.Photo
                                                }
                                                className="img-fluid blur-up lazyload bg-img"
                                                alt=""
                                                style={{ display: "none" }}
                                              />
                                            </a>
                                          </div>
                                          <div className="cart-info cart-wrap">
                                            {/* <button
                                              data-bs-toggle="modal"
                                              data-bs-target="#addtocart"
                                              title="Add to cart"
                                              // onClick={handelAdeedToCart}
                                            >
                                              <i className="ti-shopping-cart"></i>
                                            </button>{" "} */}
                                            <i
                                              className="fa fa-whatsapp"
                                              onClick={() =>
                                                ContactOnWP({
                                                  ShopInfo,
                                                  Product: product,
                                                })
                                              }
                                            ></i>
                                            <a
                                              title="Add to Wishlist"
                                              onClick={() =>
                                                Wishlist &&
                                                Wishlist.some(
                                                  (wish) =>
                                                    wish.ProductID ===
                                                    product.ProductID
                                                )
                                                  ? handelRemoveWishList(
                                                      product
                                                    )
                                                  : handelAddWishList(product)
                                              }
                                            >
                                              <i
                                                className={
                                                  Wishlist &&
                                                  Wishlist.some(
                                                    (wish) =>
                                                      wish.ProductID ===
                                                      product.ProductID
                                                  )
                                                    ? "fa fa-heart"
                                                    : "ti-heart"
                                                }
                                                aria-hidden="true"
                                                style={{
                                                  color:
                                                    Wishlist &&
                                                    Wishlist.some(
                                                      (wish) =>
                                                        wish.ProductID ===
                                                        product.ProductID
                                                    )
                                                      ? "red"
                                                      : "initial",
                                                }}
                                              ></i>
                                            </a>{" "}
                                            <a
                                              href="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#quick-view"
                                              title="Quick View"
                                              onClick={() =>
                                                openQuickView(product)
                                              }
                                            >
                                              <i
                                                className="ti-search"
                                                aria-hidden="true"
                                              ></i>
                                            </a>{" "}
                                            <a
                                              href="compare.html"
                                              title="Compare"
                                            >
                                              <i
                                                className="ti-reload"
                                                aria-hidden="true"
                                              ></i>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="product-detail">
                                          <div>
                                            <div className="rating">
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>{" "}
                                              <i className="fa fa-star"></i>
                                            </div>
                                            <a>
                                              {/* <h6
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  ProductDetailstruncatedHtml,
                                              }}
                                            /> */}
                                              <h6>{product.ProductName}</h6>
                                            </a>

                                            <h4>
                                              {product.HasDiscount
                                                ? CurrencyConverter(
                                                    product.DiscountPrice
                                                  )
                                                : product.Price > 0
                                                ? CurrencyConverter(
                                                    product.Price
                                                  )
                                                : ""}
                                            </h4>
                                            <del>
                                              {product.HasDiscount
                                                ? CurrencyConverter(
                                                    product.Price
                                                  )
                                                : ""}
                                            </del>
                                            {/* <ul className="color-variant">
                                              <li className="bg-light0"></li>
                                              <li className="bg-light1"></li>
                                              <li className="bg-light2"></li>
                                            </ul> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                            : ""}
                        </div>
                      </div>
                      <div className="product-pagination">
                        <div className="theme-paggination-block">
                          <div className="container-fluid p-0">
                            <div className="row">
                              <div className="col-xl-6 col-md-6 col-sm-12">
                                <nav aria-label="Page navigation">
                                  <ul className="pagination">
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="#"
                                        aria-label="Previous"
                                        onClick={() =>
                                          curentPageNumber !== 0
                                            ? setCurentPageNumber(
                                                curentPageNumber - 1
                                              )
                                            : alert(pageNumbers)
                                        }
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-left"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        <span className="sr-only">
                                          Previous
                                        </span>
                                      </a>
                                    </li>
                                    {pageNumbers.map((number) => (
                                      <li key={number} className="page-item">
                                        <a
                                          className="page-link"
                                          style={
                                            number === curentPageNumber
                                              ? {
                                                  backgroundColor: "#F0583D",
                                                  color: "#fff",
                                                }
                                              : {}
                                          }
                                          href="#"
                                          onClick={() =>
                                            setCurentPageNumber(number)
                                          }
                                        >
                                          {number}
                                        </a>
                                      </li>
                                    ))}
                                    <li className="page-item">
                                      <a
                                        className="page-link"
                                        href="#"
                                        aria-label="Next"
                                        onClick={() =>
                                          pageNumbers.length > curentPageNumber
                                            ? setCurentPageNumber(
                                                curentPageNumber + 1
                                              )
                                            : alert(pageNumbers)
                                        }
                                      >
                                        <span aria-hidden="true">
                                          <i
                                            className="fa fa-chevron-right"
                                            aria-hidden="true"
                                          ></i>
                                        </span>{" "}
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="product-search-count-bottom">
                                  <h5>
                                    Showing Products {startProduct} -{" "}
                                    {endProduct} of {repeatedProducts.length}{" "}
                                    Results
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CategoriesSection.propTypes = {
  isLogedIn: PropTypes.object.isRequired,
};
