
import CryptoJS from "crypto-js";

export const encrypt = (values) =>{

    const secretKey = "akeylkey"; 
    const data = JSON.stringify(values);
    const encryptedProduct = CryptoJS.AES.encrypt(data, secretKey).toString();
    const encodedEncryptedProduct = encodeURIComponent(encryptedProduct);

    return encodedEncryptedProduct

}

export const decrypt = (encodedEncryptedData) => {
    const secretKey = "akeylkey";  

    // Decode the encrypted data from URL encoding
    const encryptedData = decodeURIComponent(encodedEncryptedData);

    // Decrypt the data
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8); 
    try {
        return JSON.parse(decryptedData);
    } catch (e) {
        console.error("Decryption failed", e);
        return null;
    }
};