const ShopID = sessionStorage.getItem('ShopID')
export const GetWishlist = async () => {    
 const token = await localStorage.getItem('Token');
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetWishlist", CustomerID:token, ShopID: ShopID }),
        // credentials: "include",
      }); 
      if (!response.ok) { 
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`); 
      } 
      const data = await response.json();  
      // console.log(data)
      return data.data;
      
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  };

export const AddWishlist = async (item) => {
    const token = await localStorage.getItem('Token');
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "AddWishlist", CustomerID:token, ShopID: ShopID, item: item  }),
      });  
      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }  
      const data = await response.json();  
      // console.log(data)
      return data.data;      
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  };



export const DeleteWishlist = async (item) => { 
    const token = await localStorage.getItem('Token');
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "DeleteWishlist", CustomerID:token, ShopID: ShopID, item: item }),
      });  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }  
      const data = await response.json();  
      console.log(data)
      return data.data;      
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  };