import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminUrl } from "../components/urls";
import { CurrencyConverter } from "../components/Currency";
import { CheckOut } from "./checkout";
import { GiConsoleController } from "react-icons/gi";

export const GetCart = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cart, setCart] = useState(false);
  const [CartTotalPrice, setCartTotalPrice] = useState("");
// console.log(cart.length)
  const navigate = useNavigate();
  // console.log(cart)
  useEffect(() => {
    const checkSessionStorage = () => {
      const currentValue = sessionStorage.getItem("modalIsOpen") === "true";
      if (currentValue !== modalIsOpen) {
        setModalIsOpen(currentValue);
      }
    };

    const updateCart = () => {
      const cartStored = localStorage.getItem("cart");
      if (cartStored) {
        const cartStd = JSON.parse(cartStored);
        setCart(cartStd);

        const totalPrice = cartStd.reduce((sum, product) => {
          const price = parseFloat(
            product.Product.HasDiscount
              ? product.Product.DiscountPrice * product.Qty
              : product.Product.Price * product.Qty
          );
          return sum + price;
        }, 0);

        setCartTotalPrice(totalPrice);
      }
    };
    
    checkSessionStorage();
    updateCart();   
    const intervalId = setInterval(updateCart, 1000);
    return () => clearInterval(intervalId);
    
  }, [modalIsOpen, setCart, setCartTotalPrice]);

  const handleCartRemove = (index) => {
    // Get the current cart from state
    const updatedCart = [...cart];

    // Remove item at specified index
    updatedCart.splice(index, 1);

    // Update local storage
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    // Update state
    setCart(updatedCart);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <CheckOut show={modalShow} onHide={() => setModalShow(false)} />
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="cart_counter">
                <div className="countdownholder">
                  Your cart will be expired in<span id="timer"></span> minutes!
                </div>
                <button
                  onClick={() => setModalShow(true)}
                  className="cart_checkout btn btn-solid btn-xs"
                disabled={cart.length < 1}
                >
                  Check Out
                </button>
              </div>
            </div>
            <div className="col-sm-12 table-responsive-xs">
              <table className="table cart-table">
                <thead>
                  <tr className="table-head">
                    <th scope="col">image</th>
                    <th scope="col">product name</th>
                    <th scope="col">price</th>
                    <th scope="col">quantity</th>
                    <th scope="col">action</th>
                    <th scope="col">total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart &&
                    cart.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <a href="#">
                            <img
                              src={adminUrl + "/backend/" + item.Image}
                              alt=""
                            />
                          </a>
                        </td>
                        <td>
                          <a href="#">{item.Product.ProductName}</a>
                          <div className="mobile-cart-content row">
                            <div className="col">
                              <div className="qty-box">
                                <div className="input-group">
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control input-number"
                                    value={item.Qty}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <h2 className="td-color">
                                {item.Product.HasDiscount
                                  ? CurrencyConverter(
                                      item.Product.DiscountPrice
                                    )
                                  : CurrencyConverter(item.Product.Price)}
                              </h2>
                            </div>
                            <div className="col">
                              <h2 className="td-color">
                                <a
                                  className="icon"
                                  onClick={() => handleCartRemove(i)}
                                >
                                  <i className="ti-close"></i>
                                </a>
                              </h2>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h2>
                            {item.Product.HasDiscount
                              ? CurrencyConverter(item.Product.DiscountPrice)
                              : CurrencyConverter(item.Product.Price)}
                          </h2>
                        </td>
                        <td>
                          <div className="qty-box">
                            <div className="input-group">
                              <input
                                type="number"
                                name="quantity"
                                className="form-control input-number"
                                value={item.Qty}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            className="icon"
                            onClick={() => handleCartRemove(i)}
                          >
                            <i className="ti-close"></i>
                          </a>
                        </td>
                        <td>
                          <h2 className="td-color">
                            {item.Product.HasDiscount
                              ? CurrencyConverter(
                                  item.Product.DiscountPrice * item.Qty
                                )
                              : CurrencyConverter(
                                  item.Product.Price * item.Qty
                                )}
                          </h2>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="table-responsive-md">
                <table className="table cart-table ">
                  <tfoot>
                    <tr>
                      <td>total price :</td>
                      <td>
                        <h2>{CurrencyConverter(CartTotalPrice)}</h2>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="row cart-buttons">
            {/* <div className="col-6">
            <a href="#" className="btn btn-solid">
              continue shopping
            </a>
          </div> */}
            <div className="col-12">
              <button
                onClick={() => setModalShow(true)}
                className="cart_checkout btn btn-solid btn-xs"
                disabled={cart.length < 1}
              >
                Check Out
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
