import { useState } from "react"; 
import  {LoginComponent}  from "../data/userController";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types"; 
import { useEffect } from "react";

export const Login = ({setLogedin}) => { 
  const [CuUsername, setCuUsername] = useState("");
  const [CuPassword, setCuPassword] = useState("");
  const userAuth = { user: CuUsername, pass: CuPassword }; 
const navigate = useNavigate()
 

useEffect(() => {
  const fetchProduct = async () => {    
    window.scrollTo(0, 0);  
  };
  fetchProduct();
}, []);

  return (
    <section className="login-page section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h3>Login</h3>
            <div className="theme-card">
              <form className="theme-form" onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setCuUsername(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="review">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="review"
                    placeholder="Enter your password"
                    required
                    onChange={(e) => setCuPassword(e.target.value)}
                  />
                </div>               
                <LoginComponent userAuth={userAuth} setLogedin={setLogedin} />
              </form>
            </div>
          </div>
          <div className="col-lg-6 right-login">
            <h3>New Customer</h3>
            <div className="theme-card authentication-right">
              <h6 className="title-font">Create An Account</h6>
              <p>
                Sign up for a free account at our store. Registration is quick
                and easy. It allows you to be able to order from our shop. To
                start shopping click register.
              </p>
              <a onClick={()=> navigate('/Register')} className="btn btn-solid">
                Create an Account
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  setLogedin: PropTypes.object.isRequired, 
};
Login.defaultProps = {
  setLogedin: {}, 
};
