const ShopID = sessionStorage.getItem('ShopID')

export const GetAddress = async () => {
  const token = localStorage.getItem('Token');
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetAddress", CustomerID:token, ShopID: ShopID  }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json();  
      return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
    }
  };
export const SetAddress = async (NewAddress) => {
  const token = localStorage.getItem('Token');
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "SetAddress", CustomerID:token, NewAddress:NewAddress, ShopID: ShopID  }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json();   
      return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
    }
  };
  