import { useEffect, useState } from "react";
import { DeleteWishlist, GetWishlist } from "../data/wishlistController";
import { adminUrl } from "../components/urls";
import { QuickViewModal } from "../components/QuickViewModal"; 
import { Login } from "../user/login";

export const WishlistSection = () => {
  const [Wishlist, setWishlist] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthenticated, setLogedin] = useState(false); 

  const handelRemoveWishList = (product) => {
    DeleteWishlist(product);
    const updatedWishlist = Wishlist.filter(item => item.ProductID !== product.ProductID);
    setWishlist(updatedWishlist);
  };
    console.log(isAuthenticated) 
  const openQuickView = async (product) => {
    // console.log(product)
    await setSelectedProduct(product);
    setIsModalOpen(true);
  }; 
  
  useEffect(() => {  
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);

  return  (
    <>
      <section className="wishlist-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 table-responsive-xs">
              <table className="table cart-table">
                <thead>
                  <tr className="table-head">
                    <th scope="col">Image</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Availability</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Wishlist && Wishlist.length > 0 ? (
                    Wishlist.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <a href="#">
                            <img
                              src={adminUrl + "/backend/" + item.Photo}
                              alt=""
                            />
                          </a>
                        </td>
                        <td>
                          <a href="#">{item.ProductName}</a>
                          <div className="mobile-cart-content row">
                            <div className="col">
                              <p>In stock</p>
                            </div>
                            <div className="col">
                              <h2 className="td-color">
                                {item.HasDiscount ? item.DiscountPrice : ""}
                              </h2>
                            </div>
                            <div className="col">
                              <h2
                                className="td-color"
                                style={{ display: "flex" }}
                              >
                                <a href="#" className="icon m-3">
                                <i className="ti-close" onClick={()=>handelRemoveWishList(item)}></i>
                                </a>
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quick-view"
                                  title="Quick View"
                                  onClick={() => openQuickView(item)}
                                  className="icon m-3"
                                >
                                  <i
                                    className="ti-search"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </h2>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h2>
                            {item.HasDiscount
                              ? item.DiscountPrice
                              : item.Price}
                          </h2>
                          <del>{item.HasDiscount ? item.Price : ""}</del>
                        </td>
                        <td>
                          <p>In stock</p>
                        </td>
                        <td>
                          <a href="#" className="icon me-3">
                            <i className="ti-close" onClick={()=>handelRemoveWishList(item)}></i>
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#quick-view"
                            title="Quick View"
                            onClick={() => openQuickView(item)}
                            className="icon me-3"
                          >
                            <i className="ti-search" aria-hidden="true"></i>
                          </a>
                          <a href="compare.html" title="Compare">
                            <i className="ti-reload" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No items in your wishlist.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row wishlist-buttons">
            <div className="col-12">
              <a href="#" className="btn btn-solid">
                Continue Shopping
              </a>
              <a href="#" className="btn btn-solid">
                Check Out
              </a>
            </div>
          </div>
        </div>
      </section>
  
      {isModalOpen && (
        <QuickViewModal
          product={selectedProduct}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedProduct(null);
          }}
        />
      )}
    </> 
    )
}  
