const ShopID = sessionStorage.getItem('ShopID')
export const SubscribeRequest = async ( index ) => { 
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "SubscribeRequest", Email: index , ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const products = data?.data || []; 
       
      return products;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
      return [];  
    }
  };