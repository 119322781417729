import PropTypes from "prop-types";
import { useEffect, useState } from "react"; 
import { GetProductByCategoryID } from "../data/productController";
import { adminUrl } from "../components/urls";
import { CategoriesSlider } from "./categoriesSlider"; 
import { QuickViewModal } from "../components/QuickViewModal";
import useWindowSize from "../components/useWindowSize";
import useShopNow from "../components/ShopNow";
import { AddWishlist, DeleteWishlist, GetWishlist } from "../data/wishlistController";
import { ContactOnWP } from "../components/whatsapp";
import { useNavigate } from "react-router-dom";
import { GetShopByID } from "../data/ShopController";
  

export const HomeProducts = ({isLogedIn}) => {
  const shopNow = useShopNow();
  const [Products, setProducts] = useState([]);

  const [Wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();

    
  const [ShopInfo, setShopInfo] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfo(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);

  const handelAddWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      const updatedWishlist = [...(Wishlist || []), product];
      setWishlist(updatedWishlist);
      AddWishlist(product);
    } else {
      navigate("/Login");
    }
  };
  const handelRemoveWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      DeleteWishlist(product);
      const updatedWishlist = Wishlist.filter(
        (item) => item.ProductID !== product.ProductID
      );
      setWishlist(updatedWishlist);
    } else {
      navigate("/Login");
    }
  };

  useEffect(() => {  
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetProductByCategoryID();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

const handelClickCategory = async (e) => {
    try {
      const data = await GetProductByCategoryID(e);  
      setProducts(data); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
   
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openQuickView = async (product) => {
    // console.log(product)
   await setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const {isMobile} = useWindowSize()
  return (
    <>
    <CategoriesSlider handelClickCategory={handelClickCategory} />
    <section>
      <div className="container ">
        <div className="row">
          {/* <div className="col-3">
                    <div className="product-5 product-m no-arrow"> */}
          {Products &&
            Products.map((Product, i) => (
              <div className={`product-box + ${isMobile ? 'col-6' : 'col-3'} mt-4`} key={i}>
                <div className="img-wrapper">
                  <div className="lable-block">
                    {/* <span className="lable-gradient">new</span> */}
                    <span className="lable4">{Product.HasDiscount ? 'on sale' : '' }</span>
                  </div>
                  <div className="front">
                        <a>
                          <img
                            src={Product.Photo ? adminUrl + "/backend/" + Product.Photo : '/src/assets/images/gradient/product/3.jpg'}
                            className="img-fluid blur-up lazyload bg-img"
                            alt=""
                            style={{ 
                              marginLeft: isMobile ? '0' : "10px" ,
                              width: "100%",
                              height:isMobile ? '200px' : "325px" 
                            }}
                          />
                        </a>
                      </div>
                      <div className="back">
                        <a style={{cursor:'pointer'}}>
                          <img
                            src={Product.Photo ? adminUrl + "/backend/" + Product.Photo : '/src/assets/images/gradient/product/3.jpg'}
                            className="img-fluid blur-up lazyload bg-img"
                            alt=""
                            style={{
                              marginLeft: isMobile ? '0' : "10px" ,
                              width: "100%",
                              height:isMobile ? '200px' : "325px" 
                            }}
                            onClick={() => shopNow(Product)}
                          />
                        </a>
                      </div>
            


                  <div className="cart-box cart-box-bottom">
                    {/* <button
                      data-bs-toggle="modal"
                      data-bs-target="#addtocart"
                      title="Add to cart"
                    >
                      <i className="ti-shopping-cart"></i>
                    </button>{" "} */}
                    
                    <i
                            className="fa fa-whatsapp"
                            onClick={() =>
                              ContactOnWP({ShopInfo,Product} 
                              )
                            }
                          ></i>
                    <a title="Add to Wishlist"                    
                    onClick={() =>
                      Wishlist && Wishlist.some(
                        (wish) => wish.ProductID === Product.ProductID
                      )
                        ? handelRemoveWishList(Product)
                        : handelAddWishList(Product)
                    }
                    >
                      <i 
                              className={ 
                                 Wishlist && Wishlist.some(
                                  (wish) => wish.ProductID === Product.ProductID
                                )
                                  ? "fa fa-heart"
                                  : "ti-heart" 
                              }  
                              aria-hidden="true"
                              style={{
                                color: Wishlist && Wishlist.some(
                                  (wish) => wish.ProductID === Product.ProductID
                                )
                                  ? "red"
                                  : "initial",
                              }}></i>
                    </a>{" "}
                    <a 
                      data-bs-toggle="modal"
                      data-bs-target="#quick-view"
                      title="Quick View"
                      onClick={()=>openQuickView(Product)}
                    >
                      <i className="ti-search" aria-hidden="true"></i>
                    </a>
                    <a  title="Compare">
                      <i className="ti-reload" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="product-detail">
                  <div className=" "> 
                      <h6>{Product.ProductName}</h6> 
                    <h4>${Product.HasDiscount ? Product.DiscountPrice : Product.Price}</h4>
                   <h4> <del> {Product.HasDiscount ? '$'+Product.Price : ''} </del></h4> 
                  </div>
                  {/* <ul className="color-variant pt-0">
                    <li className="bg-light0"></li>
                    <li className="bg-light1"></li>
                    <li className="bg-light2"></li>
                  </ul> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* </div>
        </div> */}
    </section>
    {isModalOpen && <QuickViewModal product={selectedProduct} onClose={() => setIsModalOpen(false) + setSelectedProduct()} />}

    </>
  );
};
HomeProducts.propTypes = {
  isLogedIn: PropTypes.object.isRequired,  
}; 