import { useEffect, useState } from "react";
import { GetTopTowBanners } from "../data/TopMenuController";
import { adminUrl } from "../components/urls";

export const TowBanners = () => {
  const [TopTowBanners, setTopTowBanners] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetTopTowBanners();
        setTopTowBanners(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="pb-0 ratio2_1">
      <div className="container">
        <div className="row partition2">
          {TopTowBanners &&
            TopTowBanners.map((TopTowBanner, i) => (
              <div className="col-md-6" key={i}>
                <a  >
                  <div className="collection-banner p-right text-center">
                    <div className="img-part">
                      <img
                        src={adminUrl + '/backend/' + TopTowBanner.photo}
                        className="img-fluid blur-up lazyload bg-img"
                        alt=""
                        style={{
                          // height:'425px',
                          width:'100%' 
                        }}
                      />
                    </div>
                    <div className="contain-banner">
                      <div>
                        <h4>save 30%</h4>
                        <h2>{TopTowBanner.GDesription}</h2>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          {/* <div className="col-md-6">
                    <a href="#">
                        <div className="collection-banner p-right text-center">
                            <div className="img-part">
                                <img src="/src/assets/images/sub-banner2.jpg" className="img-fluid blur-up lazyload bg-img"
                                    alt=""/>
                            </div>
                            <div className="contain-banner">
                                <div>
                                    <h4>save 60%</h4>
                                    <h2>women</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> */}
        </div>
      </div>
    </section>
  );
};
