import PropTypes from "prop-types";

export const SearchBar = ({ searchIsOpen, closeSearch }) => {
  return (
    <div
      id="search-overlay"
      className="search-overlay"
      style={{ display: searchIsOpen ? "block" : "" }}
    >
      <div>
        {" "}
        <span
          className="closebtn"
          onClick={() => closeSearch()}
          title="Close Overlay"
        >
          ×
        </span>
        <div className="overlay-content">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Search a Product"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchIsOpen: PropTypes.bool.isRequired,
  closeSearch: PropTypes.bool.isRequired,
};
