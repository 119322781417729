const ShopID = sessionStorage.getItem('ShopID')
export const GetShopByID = async () => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetShopByID", ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json(); 
      // console.log(data)
      return data.data[0];
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
    }
  };
  