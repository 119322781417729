import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Login } from "../user/login";
import useWindowSize from "../components/useWindowSize";
import { GetAddress, SetAddress } from "../data/addressController";
import { CheckAuth } from "../data/CheckAuth";
import { SetNewOrder } from "../data/demandControllert";

export const CheckOut = (props) => {
  const { isMobile } = useWindowSize();
  const [OnDeliveryIsHovered, setOnDeliveryIsHovered] = useState(false);
  const [PayOnline, setPayOnlineIsHovered] = useState(false);
  const [SelectedAddress, setSelectedAddress] = useState("");
  const [address, setAddress] = useState([]);
  const [OrderStatus, setOrderStatus] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Address3, setAddress3] = useState("");
  const [Address4, setAddress4] = useState("");

  const handelAddNewAddress = () => {
    const newAddress = {
      Address1: Address1,
      Address2: Address2,
      Address3: Address3,
      Address4: Address4
    };
    if (address) {
      address.push(newAddress);
    } else {
      setAddress([newAddress]);
    }
    setIsNewAddress(false);
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setAddress4("");
    SetAddress(newAddress);
  };
  const setLogedin = () => {
    setIsAuthenticated(true);
  };
  const cartStored = localStorage.getItem("cart");
  const data = { address: SelectedAddress, cart: JSON.parse(cartStored) };

  const handelCachOnDelivery = () => {
    SetNewOrder(data, "D")
      .then((data) => {
        // console.log("Order placed successfully:", data.log);
        if (data.status) {
          setSelectedAddress("");
          setOrderStatus(data.log);
          localStorage.setItem("cart", JSON.stringify([]));
        } 
      })
      .catch((error) => {
        console.error("Error placing order:", error);
      });
  };

  // const handelPayOnline = () => {
  //   console.log(data)
  //   SetNewOrder(data, "O")
  //     .then((data) => {
  //       console.log("Order placed successfully:", data.log);
  //       if (data.status) {
  //         setSelectedAddress("");
  //         setOrderStatus(data.log);
  //         localStorage.setItem("cart", JSON.stringify([]));
  //         window.location.href =
  //           "https://areeb2.newages-leb.com/" +
  //           data.DemandID +
  //           "/" +
  //           data.TotalPrice +
  //           "/test";
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error placing order:", error);
  //     });
  // };

  const HandelHideModal = () => {
    setSelectedAddress("");
    setOrderStatus("");
    setIsNewAddress(false);
  };

  const CheckISAuth = async () => {
    try {
      const data = await CheckAuth();
      if (data === "1331") {
        setIsAuthenticated(false);
        // logout;
      } else if (data === "2416") {
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error("Error fetching Brands:", error);
    }
  };

  CheckISAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetAddress();
        setAddress(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        if (props.onHide) props.onHide(); // Call the parent onHide function if it exists
        HandelHideModal(); // Call your custom function
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isAuthenticated
            ? SelectedAddress
              ? "Choose your payment method"
              : "Select address"
            : "Login first"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isAuthenticated ? (
          SelectedAddress ? (
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col-5"
                style={{
                  width: "45%",
                  height: isMobile ? "" : "30vh",
                  borderRadius: isMobile ? "24px" : "150px 0px 150px 10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5px",
                  cursor: "pointer",
                  boxShadow: isMobile
                    ? ""
                    : OnDeliveryIsHovered
                    ? "0px 4px 15px rgba(0, 0, 0, 0.3)"
                    : "0px 4px 5px rgba(0, 0, 0, 0.3)", // Add shadow on hover
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  // backgroundColor:'#000'
                }}
                onMouseEnter={() => setOnDeliveryIsHovered(true)}
                onMouseLeave={() => setOnDeliveryIsHovered(false)}
                onClick={() => handelCachOnDelivery()}
              >
                <img
                  src="/src/assets/images/icon/cash-on-delivery.png"
                  style={{
                    width: "124px",
                  }}
                />
              </div>
              <div
                className="col-5"
                style={{
                  backgroundColor:"#ccc",
                  width: "45%",
                  height: isMobile ? "" : "30vh",
                  borderRadius: isMobile ? "24px" : "0px 150px 10px 150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "5px",
                  // cursor: "pointer",
                  boxShadow: PayOnline
                    ? "0px 4px 15px rgba(0, 0, 0, 0.3)"
                    : "0px 4px 5px rgba(0, 0, 0, 0.3)",  
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                }}
                // onMouseEnter={() => setPayOnlineIsHovered(true)}
                // onMouseLeave={() => setPayOnlineIsHovered(false)}
                // onClick={() => handelPayOnline()}
                
              >
                <div className="payment-card-bottom">
                  <ul>
                    <li>
                      <a>
                        <img src="src/assets/images/icon/visa.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img
                          src="src/assets/images/icon/mastercard.png"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="src/assets/images/icon/paypal.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img
                          src="src/assets/images/icon/american-express.png"
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a>
                        <img src="src/assets/images/icon/discover.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : OrderStatus ? (
            OrderStatus
          ) : (
            <>
              <h1
                style={{
                  cursor: "pointer",
                  color: "green",
                  display: isNewAddress ? "none" : "",
                }}
                onClick={() => setIsNewAddress(true)}
              >
                +
              </h1>
              <div
                className="row"
                style={{
                  display: isNewAddress ? "flex" : "none",
                  padding: "4%",
                }}
              >
                <input
                  type="text"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col-12"
                  }
                  onChange={(e) => setAddress1(e.target.value)}
                  value={Address1}
                  placeholder="City"
                />
                <input
                  type="text"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col-12"
                  }
                  onChange={(e) => setAddress2(e.target.value)}
                  value={Address2}
                  placeholder="Street"
                />
                <input
                  type="text"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col-12"
                  }
                  onChange={(e) => setAddress3(e.target.value)}
                  value={Address3}
                  placeholder="Building"
                />
                <input
                  type="text"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col-12"
                  }
                  onChange={(e) => setAddress4(e.target.value)}
                  value={Address4}
                  placeholder="Floor"
                />
                <Button
                  variant="danger"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col"
                  }
                  onClick={() => setIsNewAddress(false)}
                >
                  {" "}
                  Cancel{" "}
                </Button>
                <Button
                  variant="success"
                  className={
                    isMobile
                      ? "form-control mt-2 col-12"
                      : "form-control m-2 col"
                  }
                  onClick={() => handelAddNewAddress()}
                >
                  {" "}
                  Save{" "}
                </Button>
              </div>
              {address &&
                address.map((addr, i) => (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      margin: "5px",
                      padding: "15px",
                      borderRadius: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                      cursor: "pointer",
                      display: isNewAddress ? "none" : "flex",
                    }}
                    onClick={() => setSelectedAddress(addr)}
                  >
                    {addr.Address1} {addr.Address2} {addr.Address3}{" "}
                    {addr.Address4}
                  </div>
                ))}
            </>
          )
        ) : (
          <Login setLogedin={setLogedin} />
        )}
      </Modal.Body>
      <Modal.Footer>
        {SelectedAddress ? (
          <Button
            variant="success"
            onClick={() => {
              setSelectedAddress("");
            }}
          >
            Back to Address
          </Button>
        ) : (
          " "
        )}

        <Button
          variant="danger"
          onClick={() => {
            if (props.onHide) props.onHide();
            HandelHideModal();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CheckOut.propTypes = {
   onHide: PropTypes.object.isRequired, 
}; 