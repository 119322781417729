export const Paragraph = () => {
  return (
    <>
      <div className="title1 section-t-space">
        <h4>special offer</h4>
        <h2 className="title-inner1">Latest Drops</h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="product-para">
              <p className="text-center">
                Looking for the latest trends in clothing, shoes and
                accessories? Welcome to our 'Latest Drops edit, bringing you
                all the latest styles from all your fave brands.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
