import { useEffect, useState } from "react";
import { GetShopByID } from "../data/ShopController";
import { adminUrl } from "../components/urls";
import { GetCategories } from "../data/CategoriesController";
import { GetBrands } from "../data/BrandsController";
import { SubscribeRequest } from "../data/SubscribeController";
import { useNavigate } from "react-router-dom";
// import { ShopAddress, ShopLogoURL, ShopPhoneNumber, ShopSubscribeNote, ShopSubscribeTitle, ShopSupportEmail, ShopULtxt } from "../shop"

export const Footer = () => {
  const [ShopInfos, setShopInfos] = useState([]);
  const [Email, setEmail] = useState('');
//   const [error, setError] = useState(''); 

//   const validateEmail = (email) => {
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };

const HandelSubscribe = () =>{
    if(Email === ''){
        alert('Connot be empty')
        return false
    }
   SubscribeRequest(Email)

}
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfos(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  const [Categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  const [Brands, setBrands] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetBrands();
        setBrands(data); 
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);
const navigate = useNavigate()
  const handelCategoryClick = async (Category) => { 
    navigate("Categories");
    localStorage.removeItem("TopMenu");
    localStorage.removeItem("Brand");
    localStorage.setItem("Category", JSON.stringify(Category));
  };
  const handelBrandClick = async (Brand) => { 
    navigate("Categories");
    localStorage.removeItem("TopMenu");
    localStorage.removeItem("Category");
    localStorage.setItem("Brand", JSON.stringify(Brand));
  };
  return (
    <footer className="footer-light mt-4">
      <div className="light-layout">
        <div className="container container-lg">
          <section className="small-section border-section border-top-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="subscribe">
                  <div>
                    <h4>{ShopInfos.SubscribeTitle}</h4>
                    <p>{ShopInfos.SubscribeNote}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <form onSubmit={HandelSubscribe}
                //    method="POST"
                //    action="#"
                  className="form-inline subscribe-form auth-form needs-validation"  
                >
                  <div className="form-group mx-sm-3">
                    <input
                      type="email"
                      className="form-control"
                      name="EMAIL"
                      id="mce-EMAIL"
                      placeholder="Enter your email"
                      required="required"
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-solid btn-gradient"
                    id="mc-submit"
                    // onClick={()=>HandelSubscribe()}
                  >
                    subscribe
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section className="section-b-space light-layout">
        <div className="container container-lg">
          <div className="row footer-theme partition-f">
            <div className="col-lg-4 col-md-6">
              <div className="footer-title footer-mobile-title">
                <h4>about</h4>
              </div>
              <div className="footer-contant">
                <div className="footer-logo">
                  <img
                    src={adminUrl + "/backend/" + ShopInfos.Logo}
                    style={{ height: "75px", width: "179px" }}
                    alt=""
                  />
                </div>
                <p>{ShopInfos.ULtxt}</p>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook-f"></i> 
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-rss" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col offset-xl-1">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>Categories</h4>
                </div>
                <div className="footer-contant">
                  <ul>
                    {Categories &&
                      Categories.filter(
                        (category) =>
                          category.Deleted === 0 && category.isFooter === 1
                      ).map((category, i) => (
                        <li key={i}>
                          <a style={{cursor:'pointer'}} onClick={()=>handelCategoryClick(category)}>{category.SCategory}</a>
                        </li>
                      ))}  
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>Brands</h4>
                </div>
                <div className="footer-contant">
                  <ul>
                    {Brands &&
                      Brands.filter(
                        (Brand) =>
                            Brand.deleted === 0 && Brand.IsFooter === 1
                      ).map((Brand, i) => (
                        <li key={i}>
                          <a style={{cursor:'pointer'}} onClick={()=>handelBrandClick(Brand)}>{Brand.Brand}</a>
                        </li>
                      ))} 
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="sub-title">
                <div className="footer-title">
                  <h4>store information</h4>
                </div>
                <div className="footer-contant">
                  <ul className="contact-list">
                    <li>
                      <i className="fa fa-map-marker"></i>
                      {ShopInfos.SAddress}
                    </li>
                    <li>
                      <i className="fa fa-phone"></i>Call Us:{" "}
                      {ShopInfos.PhoneNumber}
                    </li>
                    <li>
                      <i className="fa fa-envelope"></i>Email Us:{" "}
                      <a href="#">{ShopInfos.SupportEmail}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sub-footer">
        <div className="container container-lg">
          <div className="row">
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="footer-end">
                <p>
                  <i className="fa fa-copyright" aria-hidden="true"></i> 2024 AL
                  Arz Mall
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12">
              <div className="payment-card-bottom">
                <ul>
                  <li>
                    <a href="#">
                      <img src="../assets/images/icon/visa.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="../assets/images/icon/mastercard.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="../assets/images/icon/paypal.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src="../assets/images/icon/american-express.png"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="../assets/images/icon/discover.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
