import { adminUrl } from "./urls";

export const ContactOnWP = ({ ShopInfo, Product }) => {
  const phoneNumber = ShopInfo.WhatsappNumber;

  // If there's a Product, customize the message with ProductName and Link
  const message = Product 
  ? ShopInfo.WhatsappProductText
      .replace(/\${ProductName}/g, Product.ProductName)
      .replace(/\${Link}/g, adminUrl + '/backend/' + Product.Photo)
  : ShopInfo.WhatsappOriginalText;


  const encodedMessage = encodeURIComponent(message);
  const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  window.open(url, '_blank');
};
