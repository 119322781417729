import { useEffect, useRef, useState } from "react";
import { adminUrl } from "../components/urls";
import {
  GetProductGaleries,
  GetProductsInfos,
} from "../data/productController";
import PropTypes from "prop-types";
import useWindowSize from "../components/useWindowSize";
import { CurrencyConverter } from "../components/Currency";
import { CartController } from "../cart/CartController";
import "./galeries.css";
import { AddWishlist, DeleteWishlist, GetWishlist } from "../data/wishlistController";
import { IoTrashBin } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const ProductSection = ({ Product, Shop, isLogedIn }) => {
  const { isMobile } = useWindowSize();
  const [mainPhoto, setMainPhoto] = useState(Product.Photo);
  const [zoomStyle, setZoomStyle] = useState({});
  const imgRef = useRef(null);
  const handlePhotoClick = (e) => {
    setMainPhoto(e);
  };

  const [Wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();
  const handelAddWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      const updatedWishlist = [...(Wishlist || []), product];
      setWishlist(updatedWishlist);
      AddWishlist(product);
    } else {
      navigate("/Login");
    }
  };
  const handelRemoveWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      DeleteWishlist(product);
      const updatedWishlist = Wishlist.filter(
        (item) => item.ProductID !== product.ProductID
      );
      setWishlist(updatedWishlist);
    } else {
      navigate("/Login");
    }
  };

  useEffect(() => {  
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);
  const sliderRef = useRef(null);
  const imageRefs = useRef([]);

  const handleImageClick = (index) => {
    // Handle the photo click action
    handlePhotoClick(ProductGaleries[index].Photo);

    // If it's the last visible image, scroll to the next one
    if (imageRefs.current[index + 1]) {
      if (isMobile) imageRefs.current[index + 1];
      else
        imageRefs.current[index + 1].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
    if (imageRefs.current[index - 1]) {
      if (isMobile) imageRefs.current[index - 1];
      else
        imageRefs.current[index - 1].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      // imageRefs.current[index - 1]
      // .scrollIntoView({ behavior: 'smooth',block: 'start', });
    }
  };

  const handleMouseMove = (e) => {
    const img = imgRef.current;
    const rect = img.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;

    setZoomStyle({
      transformOrigin: `${xPercent}% ${yPercent}%`,
      transform: "scale(4)",
      transition: "transform 0.3s ease",
      cursor: "zoom-in",
    });
  };

  const handleMouseOut = () => {
    setZoomStyle({
      transform: "scale(1)",
      transition: "transform 0.3s ease",
    });
  };
  const [ProductGaleries, setProductGaleries] = useState([]);
  useEffect(() => {
    const fetchedProductGaleries = async () => {
      try {
        const ProductGaleries = await GetProductGaleries(Product.ProductID);
        setProductGaleries(ProductGaleries);
      } catch (err) {
        console.error("Failed to fetch products" + err);
      } finally {
        // console.log(false);
      }
    };
    fetchedProductGaleries();
    setMainPhoto(Product.Photo);
  }, [Product]);

  const [ProductInfos, setProductInfos] = useState([]);
  useEffect(() => {
    const fetchedProductInfos = async () => {
      try {
        const ProductInfos = await GetProductsInfos(Product.ProductID);
        setProductInfos(ProductInfos);
        // console.log(ProductInfos);
      } catch (err) {
        console.error("Failed to fetch products" + err);
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
    setMainPhoto(Product.Photo);
  }, [Product]);

  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [photo, setPhoto] = useState("");
  const [Qty, setQty] = useState("1");
  const [isAdded, setISadded] = useState(false);
  const [ColorSelected, setColorSelected] = useState(false);

  const handelAdeedToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const newItemAdded = {
      ProductID: Product.ProductID,
      Price: Product.HasDiscount ? Product.DiscountPrice : Product.Price,
      Image: Product.Photo,
      Product: Product,
      color: color,
      size: size,
      photo: photo,
      Qty: Qty,
    };
    cart.push(newItemAdded);

    // Stringify and save the updated cart back to localStorage
    localStorage.setItem("cart", JSON.stringify(cart));

    setISadded(true);
    setTimeout(() => {
      setISadded(false);
    }, 5000);
  };
  return (
    <section>
      <div
        className={isAdded ? "added-notification show " : "added-notification "}
      >
        <img
          src={adminUrl + "/backend/" + Product.Photo}
          className="img-fluid"
          alt=""
        />
        <h3>added to cart</h3>
      </div>
      <div className="collection-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-sm-2 col-xs-12">
              <div className="row">
                <div className="col-12 p-0">
                  <div
                    className={
                      isMobile
                        ? "slider-right-nav-row"
                        : "slider-right-nav-column"
                    }
                    ref={sliderRef}
                  >
                    {ProductGaleries &&
                      ProductGaleries.map((galery, i) => (
                        <div
                          key={i}
                          className="m-1 carousel-img"
                          ref={(el) => (imageRefs.current[i] = el)}
                          style={
                            isMobile
                              ? {
                                  width: "30%",
                                  height: "30%",
                                  padding: "4%",
                                  float: "left",
                                  // overflowY: "scroll",
                                  // overflowX: "scroll",
                                  overflow: "hidden",
                                }
                              : {}
                          }
                        >
                          <img
                            src={adminUrl + "/backend/" + galery.Photo}
                            alt=""
                            className="img-fluid blur-up lazyload"
                            onClick={() => handleImageClick(i)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-10 col-xs-12 order-up">
              <div className="product-right-slick">
                <div
                  className="img-zoom-container"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <img
                    src={`${adminUrl}/backend/${mainPhoto}`}
                    alt=""
                    className="img-fluid blur-up lazyload image_zoom_cls-0"
                    ref={imgRef}
                    style={zoomStyle}
                    onMouseMove={handleMouseMove}
                    onMouseOut={handleMouseOut}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="product-right product-description-box">
                {/* <div className="product-count">
                  <ul>
                    <li>
                      <img
                        src="src/assets/images/fire.gif"
                        className="img-fluid"
                        alt="image"
                      />
                      <span className="p-counter">37</span>
                      <span className="lang">orders in last 24 hours</span>
                    </li>
                    <li>
                      <img
                        src="src/assets/images/person.gif"
                        className="img-fluid user_img"
                        alt="image"
                      />
                      <span className="p-counter">44</span>
                      <span className="lang">active view this</span>
                    </li>
                  </ul>
                </div> */}
                <h2>{Product.ProductName}</h2>
                <div className="border-product">
                  <h6 className="product-title">product details</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Product.ProductDetails
                        ? Product.ProductDetails.substring(0, 500)
                        : "",
                    }}
                  />
                  {/* <p>
                  // {Product.ProductDetails}
                  </p> */}
                </div>
                <div className="single-product-tables border-product detail-section">
                  <table>
                    <tbody>
                      <tr>
                        <td>Shipping </td>
                        <td>{Shop.ShippingInfo}</td>
                      </tr>
                      <tr>
                        <td>Shipping Info:</td>
                        <td>{Shop.txtShipInfo}</td>
                      </tr>
                      {/* <tr>
                        <td>Material:</td>
                        <td>Crepe printed</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                {/* <div className="border-product">
                  <h6 className="product-title">share it</h6>
                  <div className="product-icon">
                    <ul className="product-social">
                      <li>
                        <a>
                          <i className="fa fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a>
                          <i className="fa fa-rss"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="border-product">
                  <h6 className="product-title">100% SECURE PAYMENT</h6>
                  <div className="payment-card-bottom">
                    <ul>
                      <li>
                        <a>
                          <img src="src/assets/images/icon/visa.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <img
                            src="src/assets/images/icon/mastercard.png"
                            alt=""
                          />
                        </a>
                      </li>
                      {/* <li>
                        <a>
                          <img src="src/assets/images/icon/paypal.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a>
                          <img
                            src="src/assets/images/icon/american-express.png"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a>
                          <img
                            src="src/assets/images/icon/discover.png"
                            alt=""
                          />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="product-right product-form-box">
                {Product.HasDiscount ? (
                  <h4>
                    <del> {CurrencyConverter(Product.Price)} </del>
                    <span>
                      {(
                        (Product.DiscountPrice * 100) / Product.Price -
                        100
                      ).toFixed(1)}{" "}
                      %
                    </span>
                  </h4>
                ) : (
                  ""
                )}
                <h3 style={{ color: "#F1654C" }}>
                  {Product.HasDiscount
                    ? CurrencyConverter(Product.DiscountPrice)
                    : Product.Price > 0
                    ? CurrencyConverter(Product.Price)
                    : ""}
                </h3>
                <ul className="color-variant">
                  {ProductInfos &&
                    ProductInfos.map((ProductInfo, i) => (
                      <li
                        key={i}
                        style={{
                          backgroundImage:
                            ProductInfo.Photo &&
                            `url("${adminUrl}/backend/${Product.Photo}")`,
                          backgroundColor: ProductInfo.color
                            ? ProductInfo.color
                            : "",
                          display:
                            ProductInfo.color === "" && ProductInfo.Photo === ""
                              ? "none"
                              : "",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          height: ColorSelected === i ? "50px" : "",
                          width: ColorSelected === i ? "50px" : "",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                        onClick={() =>
                          ProductInfo.Photo
                            ? setPhoto(ProductInfo.Photo) +
                              setColor("") +
                              setColorSelected(i)
                            : setColor(ProductInfo.color) +
                              setPhoto("") +
                              setColorSelected(i)
                        }
                        // className={ColorSelected === i && 'active'}
                      ></li>
                    ))}
                  {/* <li className="bg-light0 "></li>
                  <li className="bg-light1 "></li>
                  <li className="bg-light2"></li> */}
                </ul>

                <div
                  id="selectSize"
                  className="addeffect-section product-description border-product"
                >
                  {/* <h6 className="product-title">Sales Ends In</h6> */}
                  {/* <div className="timer">
                    <p id="demo"></p>
                  </div> */}

                  {ProductInfos.every(
                    (info) => info.Descr !== "" || info.Descr !== "null"
                  ) && <h6 className="product-title">select size</h6>}

                  {/* <div
                    className="modal fade"
                    id="sizemodal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Sheer Straight Kurta
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <img
                            src="src/assets/images/size-chart.jpg"
                            alt=""
                            className="img-fluid blur-up lazyload"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <h6 className="error-message">please select size</h6>
                  <div className="size-box">
                    <ul>
                      {ProductInfos &&
                        ProductInfos.map((ProductInfo, i) =>
                          ProductInfo.Descr ? (
                            <li
                              key={i}
                              style={{
                                borderRadius: "20%",
                                width: "auto",
                                padding: "5%",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                margin: "3px",
                              }}
                              onClick={() => setSize(ProductInfo.Descr)}
                              className={ProductInfo.Descr === size && "active"}
                            >
                              <a>{ProductInfo.Descr}</a>
                            </li>
                          ) : null
                        )}
                    </ul>
                  </div>
                  <h6 className="product-title">quantity</h6>
                  <div className="qty-box">
                    <CartController setQty={setQty} />
                  </div>
                </div>
                <div className="product-buttons">
                  <a
                    id="cartEffect"
                    className="btn btn-solid hover-solid btn-animation"
                    onClick={handelAdeedToCart}
                  >
                    add to cart
                  </a>{" "}
                  <a 
                    // className="btn btn-solid"
                    className={ 
                      Wishlist && Wishlist.some(
                        (wish) => wish.ProductID === Product.ProductID
                      )
                        ? "btn "
                        : "btn btn-solid"
                    }
                    onClick={() =>
                      Wishlist && Wishlist.some(
                        (wish) => wish.ProductID === Product.ProductID
                      )
                        ? handelRemoveWishList(Product)
                        : handelAddWishList(Product)
                    }
                  >
                    {Wishlist && Wishlist.some(
                      (wish) => wish.ProductID === Product.ProductID
                    ) ? (
                      <>
                         <IoTrashBin /> wishlist
                      </>
                    ) : (
                      "wishlist"
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductSection.propTypes = {
  Product: PropTypes.object.isRequired,
  Shop: PropTypes.object.isRequired,
  isLogedIn: PropTypes.object.isRequired,
};
ProductSection.defaultProps = {
  Product: {},
  Shop: {},
};
