import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { GetLatestDrop } from "../data/productController";
import { adminUrl } from "../components/urls";
import { QuickViewModal } from "../components/QuickViewModal";
import useShopNow from "../components/ShopNow";
import useWindowSize from "../components/useWindowSize";
import {
  AddWishlist,
  DeleteWishlist,
  GetWishlist,
} from "../data/wishlistController";
import { ContactOnWP } from "../components/whatsapp";
import { useNavigate } from "react-router-dom";
import { GetShopByID } from "../data/ShopController";

export const ProductSlider = ({ isLogedIn }) => {
  // console.log(isLogedIn.isLogedIn);
  const [LatestProduct, setLatestProduct] = useState([]);
  const shopNow = useShopNow();
  const { isMobile } = useWindowSize();
  const [Wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();

      
  const [ShopInfo, setShopInfo] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfo(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);

  const handelAddWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      const updatedWishlist = [...(Wishlist || []), product];
      setWishlist(updatedWishlist);
      AddWishlist(product);
    } else {
      navigate("/Login");
    }
  };
  const handelRemoveWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      DeleteWishlist(product);
      const updatedWishlist = Wishlist.filter(
        (item) => item.ProductID !== product.ProductID
      );
      setWishlist(updatedWishlist);
    } else {
      navigate("/Login");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetLatestDrop();
        setLatestProduct(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // 5 seconds
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openQuickView = async (product) => {
    // console.log(product)
    await setSelectedProduct(product);
    setIsModalOpen(true);
  };

  return (
    <>
      <section className="section-b-space pt-0 ratio_asos">
        <div className="container">
          <div className="row">
            <div className="col">
              <Slider {...settings}>
                {LatestProduct &&
                  LatestProduct.map((product, i) => (
                    <div className="product-box" key={i}>
                      <div
                        className="img-wrapper"
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        <div className="front">
                          <a>
                            <img
                              src={adminUrl + "/backend/" + product.Photo}
                              className="img-fluid blur-up lazyload bg-img"
                              alt=""
                              style={{
                                marginLeft: isMobile ? "0" : "10px",
                                // width: "45vw",
                                height: isMobile ? "200px" : "325px",
                              }}
                              onClick={() => shopNow(product)}
                            />
                          </a>
                        </div>
                        <div className="back">
                          <a style={{ cursor: "pointer" }}>
                            <img
                              src={adminUrl + "/backend/" + product.Photo}
                              className="img-fluid blur-up lazyload bg-img"
                              alt=""
                              style={{
                                marginLeft: isMobile ? "0" : "10px",
                                width: "100%",
                                height: isMobile ? "200px" : "325px",
                              }}
                              onClick={() => shopNow(product)}
                            />
                          </a>
                        </div>
                        <div className="cart-info cart-wrap bg-light">
                          {/* <button
                            data-bs-toggle="modal"
                            data-bs-target="#addtocart"
                            title="Add to cart"
                          >
                            <i className="ti-shopping-cart"></i>
                          </button> */}
                          <i
                            className="fa fa-whatsapp"
                            onClick={() =>
                              ContactOnWP({ShopInfo,Product:product} 
                              )
                            }
                          ></i>

                          <a
                            title="Add to Wishlist"
                            onClick={() =>
                              Wishlist &&
                              Wishlist.some(
                                (wish) => wish.ProductID === product.ProductID
                              )
                                ? handelRemoveWishList(product)
                                : handelAddWishList(product)
                            }
                          >
                            <i
                              className={
                                Wishlist &&
                                Wishlist.some(
                                  (wish) => wish.ProductID === product.ProductID
                                )
                                  ? "fa fa-heart"
                                  : "ti-heart"
                              }
                              aria-hidden="true"
                              style={{
                                color:
                                  Wishlist &&
                                  Wishlist.some(
                                    (wish) =>
                                      wish.ProductID === product.ProductID
                                  )
                                    ? "red"
                                    : "",
                              }}
                            ></i>
                          </a>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#quick-view"
                            title="Quick View"
                            onClick={() => openQuickView(product)}
                          >
                            <i className="ti-search" aria-hidden="true"></i>
                          </a>
                          <a href="compare.html" title="Compare">
                            <i className="ti-reload" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <a href="product-page(no-sidebar).html">
                          <h6> {product.ProductName} </h6>
                        </a>
                        <h4>
                          {product.HasDiscount
                            ? product.DiscountPrice
                            : product.Price}
                        </h4>
                        <del>{product.HasDiscount ? product.Price : ""}</del>
                        {/* <ul className="color-variant">
                          <li className="bg-light0"></li>
                          <li className="bg-light1"></li>
                          <li className="bg-light2"></li>
                        </ul> */}
                      </div>
                    </div>
                  ))}

                {/* Add more product-box items as needed */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (
        <QuickViewModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false) + setSelectedProduct()}
        />
      )}
    </>
  );
};

ProductSlider.propTypes = {
  isLogedIn: PropTypes.object.isRequired, 
}; 