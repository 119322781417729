import { useContext, useEffect, useState } from "react";
import { MenuLeft } from "./menuLeft";
import { SearchBar } from "./search";
import { GetTopMenu } from "../data/TopMenuController";
import { GetShopByID } from "../data/ShopController";
import useWindowSize from "../components/useWindowSize";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

export const Header = ({ isLogedIn, setLogedin }) => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const { isMobile } = useWindowSize();
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [MainMenuOpened, openMainMenu] = useState(false);
  const [TopMenus, setTopMenus] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredIndexSubMenu, setHoveredIndexSubMenu] = useState(null);

  const [ShopInfos, setShopInfos] = useState([]);

  const handelTopMenuClick = async (TopMenu) => {
    openMainMenu(false);
    navigate("Categories");
    localStorage.setItem("TopMenu", JSON.stringify(TopMenu));
    localStorage.removeItem("Category");
    localStorage.removeItem("Brand");
  };

  const [toastLoad, setToastLoad] = useState(false);
  const handelToast = () => {
    return (
      <>
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 11, display: "block" }}
        >
          <div
            id="liveToast"
            className={toastLoad ? "toast show" : "toast hide"}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <strong className="me-auto">MegaBrand</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
            <div className="toast-body">You are logedout successfuly ...</div>
          </div>
        </div>
      </>
    );
  };
  const handelLogout = () => {
    setToastLoad(true);
    setTimeout(() => {
      setToastLoad(false);
      logout();
      setLogedin(false);
    }, 3000);
  };
  const handelCategoryClick = async (Category) => {
    await openMainMenu(false);
    navigate("Categories");
    localStorage.removeItem("TopMenu");
    localStorage.removeItem("Brand");
    localStorage.setItem("Category", JSON.stringify(Category));
  };

  const [cart, setCart] = useState([]);

  useEffect(() => {
    const checkCart = () => {
      const cartStored = localStorage.getItem("cart");
      if (cartStored) {
        setCart(JSON.parse(cartStored));
      }
    };
    checkCart();
    const intervalId = setInterval(checkCart, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfos(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleSubMouseOver = (index) => { 
    setHoveredIndexSubMenu(index);
  };

  const handleSubMouseOut = () => {
    setHoveredIndexSubMenu(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetTopMenu();
        setTopMenus(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIsLoad(false);
    const timer = setTimeout(() => {
      setIsLoad(true);
    }, 500); // 1000 milliseconds = 1 second

    return () => clearTimeout(timer);
  }, []);

  const openSearch = () => {
    setSearchIsOpen(true);
  };

  const closeSearch = () => {
    setSearchIsOpen(false);
  };
  const openCart = () => {
    sessionStorage.setItem("modalIsOpen", "true");
  };

  const handelSetCurrency = (Currency) => {
    const newCurrency = JSON.stringify(Currency);
    localStorage.setItem("currency", newCurrency);
  };
  return (
    <>
      {handelToast()}
      <header>
        <div className="mobile-fix-option"></div>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="header-contact">
                  <ul>
                    <li>{ShopInfos.WelcomMsg}</li>
                    <li>
                      <i className="fa fa-phone" aria-hidden="true"></i>Call Us:
                      {ShopInfos.PhoneNumber}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                <ul className="header-dropdown">
                  <li className="mobile-wishlist" onClick={()=>navigate('/Wishlist')}>
                    <a>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="onhover-dropdown mobile-account">
                    {" "}
                    <i className="fa fa-user" aria-hidden="true"></i>
                    My Account {isLogedIn}
                    <ul className="onhover-show-div">
                      {isLogedIn ? (
                        <li>
                          <a onClick={() => handelLogout()}>Logout</a>
                        </li>
                      ) : (
                        <>
                          <li>
                            <a onClick={() => navigate("/Login")}>Login</a>
                          </li>
                          <li>
                            <a onClick={() => navigate("/Register")}>
                              register
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="main-menu">
                <MenuLeft isLoad={isLoad} />
                <div className="menu-right pull-right">
                  <div>
                    <nav id="main-nav">
                      <div className="toggle-nav">
                        <i
                          className="fa fa-bars sidebar-bar"
                          onClick={() => openMainMenu(true)}
                        ></i>
                      </div>
                      <ul
                        id="main-menu"
                        className="sm pixelstrap sm-horizontal"
                        style={{
                          right: MainMenuOpened ? 0 : isMobile ? "-410px" : "",
                          left: MainMenuOpened ? 0 : "",
                          width: isMobile ? "100vw" : "",
                        }}
                      >
                        <li onClick={() => openMainMenu(false)}>
                          <div className="mobile-back text-end">
                            Back
                            <i
                              className="fa fa-angle-right ps-2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </li>
                        <li>
                          <a
                            onClick={() => navigate("/") + openMainMenu(false)}
                            style={{ cursor: "pointer" }}
                          >
                            Home
                          </a>
                        </li>
                        {TopMenus &&
                          TopMenus.map((TopMenu, i) => (
                            <li
                              key={i}
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                              onMouseOver={() => handleMouseOver(i)}
                              onMouseOut={() => handleMouseOut(i)}
                            >
                              <a className="has-submenu highlighted">
                                <spam
                                  onClick={() => handelTopMenuClick(TopMenu)}
                                >
                                  {TopMenu.GDesription}
                                </spam>
                                <span className="sub-arrow"></span>
                              </a>
                              <ul
                                style={{
                                  display:
                                    hoveredIndex === i ? "block" : "none",
                                  // position: "absolute",
                                  width: "auto",
                                  top: "auto",
                                  left: "0px",
                                  marginTop: "0px",
                                  minWidth: "10em",
                                  maxWidth: "20em",
                                  zIndex: "3",
                                }}
                              > 
                                    {TopMenu.submenu && TopMenu.submenu.map((subMenu,j) =>  
                                <li key={j}
                                onMouseOver={() => handleSubMouseOver(j)}
                                onMouseOut={() => handleSubMouseOut(j)}>
                                  <a
                                  // onClick={() =>
                                  //   handelCategoryClick(subMenu)
                                  // } 
                                  >
                                    {subMenu.SCategory}
                                    <span className="sub-arrow"></span>
                                  </a>
                                  <ul
                                    style={{
                                      display: hoveredIndexSubMenu === j ? "block" : "none",
                                      width: "auto",
                                      top: "auto",
                                      left: "0px",
                                      marginLeft: "223px",
                                       marginTop: '-41px',
                                      minWidth: "10em",
                                      maxWidth: "20em"
                                    }}
                                  >
                                    {subMenu.subSubmenu && subMenu.subSubmenu.map((subSubmenu, x) =>
                                    <li key={x}>
                                      <a 
                                  onClick={() =>
                                    handelCategoryClick(subSubmenu)
                                  } >
                                        {subSubmenu.SCategory}
                                      </a>
                                    </li>
                                    
                                    ) }
                                     
                                  </ul>
                                </li>
                                  )}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </nav>
                  </div>
                  <div>
                    <div className="icon-nav">
                      <ul>
                        <li className="onhover-div mobile-search">
                          <div>
                            <img
                              src="/src/assets/images/icon/search.png"
                              onClick={() => openSearch()}
                              // className="img-fluid blur-up "
                              className={
                                "img-fluid" + (isLoad ? "" : " blur-up")
                              }
                              alt=""
                            />{" "}
                            <i
                              className="ti-search"
                              onClick={() => openSearch()}
                            ></i>
                          </div>

                          {/* <SearchBar
                          searchIsOpen={searchIsOpen}
                          closeSearch={closeSearch}
                        /> */}
                        </li>
                        <li className="onhover-div mobile-setting">
                          <div>
                            <img
                              src="/src/assets/images/icon/setting.png"
                              // className="img-fluid blur-up "
                              className={
                                "img-fluid" + (isLoad ? "" : " blur-up")
                              }
                              alt=""
                            />{" "}
                            <i className="ti-settings"></i>
                          </div>
                          <div className="show-div setting">
                            <h6>language</h6>
                            <ul>
                              <li>
                                <a href="#">english</a>
                              </li>
                              {/* <li>
                              <a href="#">french</a>
                            </li> */}
                            </ul>
                            <h6>currency</h6>
                            <ul className="list-inline">
                              {/* <li>
                              <a href="#">euro</a>
                            </li>
                            <li>
                              <a onClick={()=>handelSetCurrency({"unit":"€","rate":"0.92"})}>rupees</a>
                            </li>*/}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handelSetCurrency({
                                      unit: "L.L",
                                      rate: "90000",
                                    })
                                  }
                                >
                                  LBP
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handelSetCurrency({ unit: "$", rate: "1" })
                                  }
                                >
                                  dollar
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li
                          className="onhover-div mobile-cart"
                          onClick={() => openCart()}
                        >
                          <div>
                            <img
                              src="/src/assets/images/icon/cart-1.png"
                              // className="img-fluid blur-up "
                              className={
                                "img-fluid" + (isLoad ? "" : " blur-up")
                              }
                              alt=""
                            />{" "}
                            <i className="ti-shopping-cart"></i>
                          </div>
                          <span className="cart_qty_cls">
                            {cart ? cart.length : "0"}
                          </span>
                          {/* <ul className="show-div shopping-cart">
                          <li>
                            <div className="media">
                              <a href="#">
                                <img
                                  alt=""
                                  className="me-3"
                                  src="/src/assets/images/fashion/product/1.jpg"
                                />
                              </a>
                              <div className="media-body">
                                <a href="#">
                                  <h4>item name</h4>
                                </a>
                                <h4>
                                  <span>1 x $ 299.00</span>
                                </h4>
                              </div>
                            </div>
                            <div className="close-circle">
                              <a href="#">
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="media">
                              <a href="#">
                                <img
                                  alt=""
                                  className="me-3"
                                  src="/src/assets/images/fashion/product/2.jpg"
                                />
                              </a>
                              <div className="media-body">
                                <a href="#">
                                  <h4>item name</h4>
                                </a>
                                <h4>
                                  <span>1 x $ 299.00</span>
                                </h4>
                              </div>
                            </div>
                            <div className="close-circle">
                              <a href="#">
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="total">
                              <h5>
                                subtotal : <span>$299.00</span>
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="buttons">
                              <a className="view-cart">view cart</a>{" "}
                              <a href="#" className="checkout">
                                checkout
                              </a>
                            </div>
                          </li>
                        </ul> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
