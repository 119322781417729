import "swiper/swiper-bundle.css";
import { GetHomeSlider } from "../data/HomeSliderController";
import { useEffect, useState } from "react";
import { adminUrl } from "../components/urls";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useWindowSize from "../components/useWindowSize";

export const HomeSlider = () => {
  const [HomeSlider, setHomeSlider] = useState([]);
  const { isMobile } = useWindowSize();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetHomeSlider();
        setHomeSlider(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="p-0">
      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        // loop={true}
        autoplay={{ delay: 6000 }}
        pagination={{ clickable: true }}
        navigation={true}
      >
        {HomeSlider &&
          HomeSlider.map((slider, i) => (
            <SwiperSlide key={i}>
              <div
                className="  text-center"
                style={{
                  height: isMobile ? '40vh' : "70vh",
                  width: "100vw",
                  backgroundImage: `url('${adminUrl}/backend/${slider.Photo}')`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div
                        className="slider-contain"
                        style={{
                          left: 0,
                          bottom: 0,
                          padding: "3%",
                          textAlign: "start",
                          zIndex: "800",
                        }}
                      >
                        {/* <div>
                          <h4>{slider.Txt1}</h4>
                          <h1>{slider.Txt2}</h1>
                          <a href="#" className="btn btn-solid">
                            shop now
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </section>
  );
};
