export const CurrencyConverter = (amount) => {

 
  const currency = JSON.parse(localStorage.getItem('currency')) || {"unit":"$","rate":"1"};
 

  const result = amount * currency.rate 
  const formattedResult = result.toLocaleString();

  try {
    return currency.unit + "" + formattedResult;
  } catch (e) {
    console.error(" failed", e);
    return null;
  }
};
