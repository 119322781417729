import { Routes, Route, useLocation } from "react-router-dom"; 
import { useEffect, useState } from "react";

import { Home } from "./home";
import { CategoriesSection } from "./categories";
import { GetCart } from "./cart";
import { ProductInfo } from "./product";
import { WishlistSection } from "./wishlist";

import { Login } from "./user/login";
import { Register } from "./user/register";

import { NotFoundPage } from "./NotFoundPage";
import { CheckAuth } from "./data/CheckAuth";
import { Header } from "./header"; //original header
// import { Header } from "./header/megabrandIndex";  // megabrand header
import { Footer } from "./footer";

function GetRouter() {
  const location = useLocation() 
  const previousPage = location.pathname
  if(previousPage === "/Login" || previousPage === "/Register"){
    localStorage.setItem('previousPage1', JSON.stringify(previousPage))
  }else{
    // const previousPage = location.pathname.replace('/', ''); 
  localStorage.setItem('previousPage', previousPage)
}
  const [isLogedIn, setLogedin] = useState(false); 
  const fetchData = async () => {
    try {
      const data = await CheckAuth();
      if (data === "1331") {
        setLogedin(false);
        // logout;
      } else if (data === "2416") {
        setLogedin(true);
        // login;
      }
    } catch (error) {
      console.error("Error fetching Brands:", error);
    }
  };

  const checkLocalStorageChange = () => {
    // const storedValue = localStorage.getItem('yourLocalStorageKey');

    fetchData();
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("click", () => checkLocalStorageChange());
    window.removeEventListener("click", () => checkLocalStorageChange());
  }, [checkLocalStorageChange]);
  
  return (
    <>
    
    <Header isLogedIn={isLogedIn} setLogedin={setLogedin}/>
    <Routes>
      {isLogedIn ? (
        <>
          {/* <Route path="/Cart" element={<GetCart />} /> */}
          <Route path="/Wishlist" element={<WishlistSection />} />
        </>
      ) : (
        <>
          <Route path="/Login" element={<Login setLogedin={setLogedin}/>} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Wishlist" element={<Login />} />
        </>
      )}

      <Route path="/Cart" element={<GetCart />} />  
      <Route path="/" element={<Home isLogedIn={isLogedIn}/>} />
      <Route path="/Categories" element={<CategoriesSection isLogedIn={isLogedIn} />} />
      <Route path="/Product" element={<ProductInfo isLogedIn={isLogedIn} />} />

          
      <Route path="*" element={<Home isLogedIn={isLogedIn}/>} />
      {/* <Route path="*" element={<NotFoundPage />} /> */}
    </Routes>
    <Footer />
    </>
  );
}

export default GetRouter;
