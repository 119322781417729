import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { GetrelatedProducts } from "../data/productController";
import { adminUrl } from "../components/urls";
import useShopNow from "../components/ShopNow";
import { CurrencyConverter } from "../components/Currency";
import { QuickViewModal } from "../components/QuickViewModal";
import {
  AddWishlist,
  DeleteWishlist,
  GetWishlist,
} from "../data/wishlistController";
import { ContactOnWP } from "../components/whatsapp";
import { useNavigate } from "react-router-dom";
import { GetShopByID } from "../data/ShopController";
export const Related = ({ Product, isLogedIn }) => {
  const shopNow = useShopNow();

  const [Wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();
       
  const [ShopInfo, setShopInfo] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfo(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);

  const handelAddWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      const updatedWishlist = [...(Wishlist || []), product];
      setWishlist(updatedWishlist);
      AddWishlist(product);
    } else {
      navigate("/Login");
    }
  };
  const handelRemoveWishList = (product) => {
    if (isLogedIn.isLogedIn) {
      DeleteWishlist(product);
      const updatedWishlist = Wishlist.filter(
        (item) => item.ProductID !== product.ProductID
      );
      setWishlist(updatedWishlist);
    } else {
      navigate("/Login");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const Gwishlist = await GetWishlist();
        setWishlist(Gwishlist);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWishlist();
  }, []);
  const [Relateds, settRelated] = useState([]);

  useEffect(() => {
    const fetchedProductInfos = async () => {
      try {
        const RelatedProducts = await GetrelatedProducts(Product.CategoryID);
        settRelated(RelatedProducts);
        // console.log(ProductInfos);
      } catch (err) {
        console.error("Failed to fetch products" + err);
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
  }, [Product]);

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openQuickView = async (product) => {
    // console.log(product)
    await setSelectedProduct(product);
    setIsModalOpen(true);
  };

  return (
    <>
      <section className="section-b-space ratio_asos">
        <div className="container">
          <div className="row">
            <div className="col-12 product-related">
              <h2>related products</h2>
            </div>
          </div>
          <div className="row search-product">
            {Relateds &&
              Relateds.map((Related, i) => (
                <div
                  key={i}
                  className="col-xl-2 col-md-4 col-6 m-0 rounded p-2"
                  // onClick={() => shopNow(Related)}
                >
                  <div className="product-box">
                    <div className="img-wrapper">
                      <div className="front">
                        <a>
                          <img
                            src={adminUrl + "/backend/" + Related.Photo}
                            className="img-fluid blur-up lazyload bg-img rounded"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="back">
                        <a>
                          <img
                            src={adminUrl + "/backend/" + Related.Photo}
                            className="img-fluid blur-up lazyload bg-img"
                            alt=""
                            onClick={() => shopNow(Related)}
                          />
                        </a>
                      </div>
                      <div className="cart-info cart-wrap">
                        {/* <button
                        data-bs-toggle="modal"
                        data-bs-target="#addtocart"
                        title="Add to cart"
                      >
                        <i className="ti-shopping-cart"></i>
                      </button>{" "} */}
                        <i
                          className="fa fa-whatsapp"
                            onClick={() =>
                              ContactOnWP({ShopInfo,Product:Related} 
                              )
                            }
                        ></i>
                        <a
                          title="Add to Wishlist"
                          onClick={() =>
                            Wishlist && Wishlist.some(
                              (wish) => wish.ProductID === Related.ProductID
                            )
                              ? handelRemoveWishList(Related)
                              : handelAddWishList(Related)
                          }
                        >
                          <i
                            className={
                              Wishlist &&
                              Wishlist.some(
                                (wish) => wish.ProductID === Related.ProductID
                              )
                                ? "fa fa-heart"
                                : "ti-heart"
                            }
                            aria-hidden="true"
                            style={{
                              color:
                                Wishlist &&
                                Wishlist.some(
                                  (wish) => wish.ProductID === Related.ProductID
                                )
                                  ? "red"
                                  : "initial",
                            }}
                          ></i>
                        </a>{" "}
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#quick-view"
                          title="Quick View"
                          onClick={() => openQuickView(Related)}
                        >
                          <i className="ti-search" aria-hidden="true"></i>
                        </a>{" "}
                        <a title="Compare">
                          <i className="ti-reload" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                    <div className="product-detail">
                      <div className="rating">
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>{" "}
                        <i className="fa fa-star"></i>
                      </div>
                      <a href="product-page(no-sidebar).html">
                        <h6>{Related.ProductName}</h6>
                      </a>
                      <h4>
                        {Related.HasDiscount
                          ? CurrencyConverter(Related.DiscountPrice)
                          : CurrencyConverter(Related.Price)}
                      </h4>
                      <del>
                        {Related.HasDiscount
                          ? CurrencyConverter(Related.Price)
                          : ""}
                      </del>
                      {/* <ul className="color-variant">
                  <li className="bg-light0"></li>
                  <li className="bg-light1"></li>
                  <li className="bg-light2"></li>
                </ul> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {isModalOpen && (
        <QuickViewModal
          product={selectedProduct}
          onClose={() => setIsModalOpen(false) + setSelectedProduct()}
        />
      )}
    </>
  );
};

Related.propTypes = {
  Product: PropTypes.object.isRequired,
  isLogedIn: PropTypes.object.isRequired, 
};
Related.defaultProps = {
  Product: {},
};
 