// import { QuickViewModal } from "../components/QuickViewModal";
// import { CategoriesSlider } from "./categoriesSlider";
// import { CategoriesSlider2 } from "./categoriesSlider2";
import { HomeProducts } from "./homeProducts";
import { HomeSlider } from "./homeSlider";
import { Paragraph } from "./paragraph";
import { Parallaxbanner } from "./parallaxbanner";
import { ProductSlider } from "./productSlider";
import { TowBanners } from "./towBanners";

export const Home = (isLogedIn) => { 
  return (
    <> 
      <HomeSlider />
      <TowBanners />
      <Paragraph />
      <ProductSlider isLogedIn={isLogedIn} />
      <Parallaxbanner /> 
      <HomeProducts isLogedIn={isLogedIn} /> 
    </>
  );
};
