import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GetBrands } from "../data/BrandsController";  
import { GetShopByID } from "../data/ShopController";
import { adminUrl } from "../components/urls";
import { useNavigate } from "react-router-dom";

export const MenuLeft = ({ isLoad }) => {
  const [navIsOpen, setNavIsOpen] = useState(false); 
  const [Brands, setBrands] = useState([]);

  const[ShopInfos, setShopInfos] = useState([]) 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID(); 
        setShopInfos(data) 
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetBrands();
        setBrands(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []); 

  const openNav = () => {
    setNavIsOpen(true);
  };

  const closeNav = () => {
    setNavIsOpen(false);
  };

const navigate = useNavigate()

  const handelBrandClick = async (Brand) => {
    await setNavIsOpen(false);
    navigate("Categories");  
    localStorage.setItem("Brand", JSON.stringify(Brand));
    localStorage.removeItem("Category") 
    localStorage.removeItem("TopMenu")
  };

  return (
    <div className="menu-left">
      <div className="navbar">
        <a onClick={() => openNav()}>
          <div className="bar-style">
            <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>
          </div>
        </a>
        <div
          id="mySidenav"
          className={"sidenav" + (navIsOpen ? " open-side" : "")}
          style={{overflowX:'hidden', overflowY:'scroll'}}
        >
          <a className="sidebar-overlay" onClick={() => closeNav()}></a>
          <nav className="sidebar-unset">
            <div onClick={() => closeNav()}>
              <div className="sidebar-back text-start">
                <i className="fa fa-angle-left pe-2" aria-hidden="true"></i>{" "}
                Back
              </div>
            </div>
            <ul id="sub-menu" className="sm pixelstrap sm-vertical hover-unset">

                {Brands && Brands.map((Brand,i)=>
              <li
                // onMouseEnter={() => setSubOver(true)}
                // onMouseLeave={() => setSubOver(false)}
                key={i}
              >
                <a
                  href="#"
                  className="has-submenu highlighted"
                  aria-expanded="true"
                  onClick={()=> handelBrandClick(Brand)}
                >
                  {Brand.Brand}
                  {/* <span className="sub-arrow"></span> */}
                </a>
                </li> 
                )} 
            </ul>
          </nav>
        </div>
      </div>
      <div className="brand-logo">
        <a onClick={() => navigate('/')}>
          <img
            src={adminUrl + '/backend/' + ShopInfos.Logo}
            style={{height:'75px',width:'179px'}}
            className={"img-fluid" + (isLoad ? "" : " blur-up")} 
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

MenuLeft.propTypes = {
  isLoad: PropTypes.bool.isRequired,
};
