import { useEffect, useState } from "react";
import { adminUrl } from "./urls";
import { CurrencyConverter } from "./Currency";
import { useNavigate } from "react-router-dom";

export const ModalCart = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cart, setCart] = useState(false);
  const [CartTotalPrice, setCartTotalPrice] = useState(''); 

 const navigate = useNavigate() 

  const handelViewCart = () => { 
    sessionStorage.setItem("modalIsOpen", false);
    navigate('/cart' )
  }
  useEffect(() => {
    const checkSessionStorage = () => {
      const currentValue = sessionStorage.getItem("modalIsOpen") === "true";
      if (currentValue !== modalIsOpen) {
        setModalIsOpen(currentValue);
      }
  
      const cartStored = localStorage.getItem("cart");
      if (cartStored) {
        const cartStd = JSON.parse(cartStored);
        setCart(cartStd);
  
        const totalPrice = cartStd.reduce((sum, product) => {
          const price = parseFloat(product.Product.HasDiscount ? (product.Product.DiscountPrice * product.Qty)  : (product.Product.Price * product.Qty));
          return sum + price;
        }, 0);

        setCartTotalPrice(totalPrice);
      }
    };
  
    const intervalId = setInterval(checkSessionStorage, 2000);
    return () => clearInterval(intervalId);
  }, [modalIsOpen, setCart, setCartTotalPrice]);
  

  const closeCart = () => {
    sessionStorage.setItem("modalIsOpen", "false");
    setModalIsOpen(false);
  };

  const handleCartRemove = (index) => {
    // Get the current cart from state
    const updatedCart = [...cart];
    
    // Remove item at specified index
    updatedCart.splice(index, 1);
    
    // Update local storage
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    
    // Update state
    setCart(updatedCart);
  };
  return (
    <div
      id="cart_side"
      className={
        modalIsOpen ? "add_to_cart bottom open-side" : "add_to_cart bottom"
      }
    >
      <a className="overlay" onClick={() => closeCart()}></a>
      <div className="cart-inner">
        <div className="cart_top">
          <h3>my cart</h3>
          <div className="close-cart">
            <a onClick={() => closeCart()}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <div className="cart_media">
          <ul className="cart_product">
            {cart &&
              cart.map((item, i) => (
                <li key={i}>
                  <div className="media">
                    <a href="#">
                      <img
                        alt=""
                        className="me-3"
                        src={adminUrl+'/backend/'+item.Product.Photo}
                      />
                    </a>
                    <div className="media-body">
                      <a href="#">
                        <h4>{item.Product.ProductName}</h4>
                      </a>
                      <h4>
                        <span>{item.Product.HasDiscount ? item.Qty + ' x ' + CurrencyConverter(item.Product.DiscountPrice) : item.Product.Price > 0 ? item.Qty +  ' x ' +  CurrencyConverter(item.Product.Price) : '' }</span>
                      </h4>
                    </div>
                  </div>
                  <div className="close-circle">
                    <a href="#">
                      <i className="ti-trash" aria-hidden="true"
                      onClick={()=>handleCartRemove(i)}
                      ></i>
                    </a>
                  </div>
                </li>
              ))}
          </ul>
          <ul className="cart_total">
            <li>
              <div className="total">
                <h5>
                  subtotal : <span>{CurrencyConverter(CartTotalPrice)}</span>
                </h5>
              </div>
            </li>
            <li>
              <div className="buttons">
                <a onClick={()=>handelViewCart()} className="btn btn-solid btn-xs view-cart">
                  view cart
                </a>
                {/* <a href="#" className="btn btn-solid btn-xs checkout">
                  checkout
                </a> */}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
