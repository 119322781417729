const ShopID = sessionStorage.getItem('ShopID')
export const SetNewOrder = async (order, paymentMethod) => {
  
  const token = localStorage.getItem("Token");
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Connection: "keep-alive",
      },
      body: JSON.stringify({
        action: "SetNewOrder",
        cart: order.cart,
        address: order.address,
        CustomerID: token,
         ShopID: ShopID 
      }),
    });

    if (!response.ok) {
      throw new Error(
        `HTTP error! status: ${response.status} - ${response.statusText}`
      );
    }
    const data = await response.json(); 
    console.log(data); 
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
  }
};
