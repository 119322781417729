import { useContext, useState } from "react";
import { AuthContext } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
const ShopID = sessionStorage.getItem('ShopID')
export const LoginComponent = ( userAuth )=> {
  const { login } = useContext(AuthContext);
  const [error, setError] = useState(false);

  const navigate = useNavigate()
  
  const handleLogin = async ( userAuth ) => {
    // console.log(userAuth.userAuth.user)
    if (userAuth.userAuth.user === "") {
      setError("Email cant be empty");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userAuth.userAuth.user)) {
      setError("Invalid email format");
      return false;
    }
    if (userAuth.userAuth.pass === "") {
      setError("Password cant be empty");
      return false;
    }
    setError(false);

    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "CheckAuth", data: userAuth.userAuth , ShopID: ShopID }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.isLogedin) {
       const previousPage = localStorage.getItem('previousPage') 
        login(data);
        // setLogedin(true)
        if(previousPage === "/cart"){
          navigate(previousPage)
          window.location.reload();

        }else{
        navigate(previousPage)

        }
      } else {
        setError(data.log);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div>
      {/* Add your login form or UI elements here */}
      <button
        type="button"
        className="btn btn-solid"
        onClick={() => handleLogin(userAuth)}
      >
        Login
      </button>
      {error ? (
        <div className="alert alert-warning  mt-2" role="alert">
          {error}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
