const ShopID = sessionStorage.getItem('ShopID')
export const GetProductsByID = async ( index ) => {
  try {
    const response = await fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "GetProductsByID", ProductID: index, ShopID: ShopID }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    } 
    const data = await response.json();
    const products = data?.data || [];   
    return products;
  } catch (error) {
    console.error("Error fetching data:", error);
    console.log(error.message);
    return [];  
  }
};

export const GetLatestDrop = async () => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetLatestDrop", ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json(); 
      return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
    }
  };
  
export const GetProductByCategoryID = async (e) => { 
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "GetProductsByCategories", CategoryID: e ? e : '', ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
  
      const data = await response.json(); 
      return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
    }
  };
  
  export const GetProductsInfos = async ( index ) => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "GetProductsInfosByID", ProductID: index, ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const products = data?.data || []; 
       
      return products;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
      return [];  
    }
  };

  export const GetFilteredProducts = async ( index ) => {
    // console.log(index)
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "GetFilteredProducts", filter: index, ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const products = data?.data || []; 
       
      return products;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
      return [];  
    }
  };

  export const GetrelatedProducts = async ( index ) => { 
  
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "GetrelatedProductsByID", CategoryID: index, ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const RelatedProducts = data?.data || [];  
      return RelatedProducts;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
      return [];  
    }
  };
  export const GetProductGaleries = async ( index ) => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "GetProductsGaleriesByID", ProductID: index, ShopID: ShopID }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const products = data?.data || [];  
      return products;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(error.message);
      return [];  
    }
  };