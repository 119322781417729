import  { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";  

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if there's an authentication status in localStorage
    const storedAuthStatus = localStorage.getItem('isAuthenticated');
    if (storedAuthStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (data) => { 
    if(data){
    setIsAuthenticated(true); 

    localStorage.setItem('Token', data.token);
    }
  };

  const logout = () => {
    // Update state and localStorage
    setIsAuthenticated(false);
    localStorage.removeItem('Token');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}; 