import { GetShopByID } from "../data/ShopController";
import { ContactOnWP } from "./whatsapp";
import { useEffect, useState } from "react";

export const StikyButtons = () => {
  const [isStikyShow, setisStikyShow] = useState(true);
  
  const [ShopInfo, setShopInfo] = useState(null); 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID();
        setShopInfo(data);
      } catch (error) {
        console.error("Error fetching Slider:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        transform: "translateY(-50%)",
        left: "20px",
        zIndex: 1000,
        fontSize: "36px",
        display: isStikyShow ? "block" : "none",
      }}
    >
      <img
        src="/src/assets/images/icon/wp-3.png"
        // className="fa fa-whatsapp"
        style={{
          cursor: "pointer",
          //   color: "#25D366",
          width: "42px",
        }}
        onClick={() => ContactOnWP({ShopInfo,product:null})}
      />

      <span
        className="position-absolute top-0 start-100 translate-middle badge rounded-pill text-dark"
        onClick={() => setisStikyShow(false)}
        style={{
          cursor: "pointer",
          width: "5px",
        }}
      >
        <i className="fa fa-close"></i>
        {/* <span className="visually-hidden">unread messages</span> */}
      </span>
      {/* </i> */}
    </div>
  );
};
