const ShopID = sessionStorage.getItem('ShopID')
export const CheckAuth = async () =>{
    const token = localStorage.getItem('Token');
    try {
      const response = await  fetch("/api/", {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ action: "UserValidate", ShopID: ShopID   }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
    //   console.log('User data:', userData);
      return userData.isLogedin
    } catch (err) {
      console.error(err.message);
    }

}
  