const ShopID = sessionStorage.getItem('ShopID')
export const GetTopMenu = async () => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "getTopMenu", ShopID: ShopID }),
        // credentials: "include",

      });
  
      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);

      }
  
      const data = await response.json();  
      // console.log(data)
      return data.data;
      
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  };

export const GetTopTowBanners = async () => {
    try {
      const response = await fetch("/api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Connection": "keep-alive",
        },
        body: JSON.stringify({ action: "getTopTowBanners", ShopID: ShopID  }), 

      });
  
      if (!response.ok) {
        // throw new Error(`HTTP error! status: ${response.status}`);
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);

      }
  
      const data = await response.json();   
      return data.data;
      
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log(`Error message: ${error.message}`);
    }
  };
  