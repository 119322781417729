import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { GetCategories } from "../data/CategoriesController";
import { adminUrl } from "../components/urls";
import PropTypes from "prop-types";  
import { AiOutlineCaretRight, AiOutlineCaretLeft  } from "react-icons/ai";
import useWindowSize from "../components/useWindowSize";



export const CategoriesSlider = ({ handelClickCategory }) => {
  const [Categories, setCategories] = useState([]);
  const {isMobile} = useWindowSize()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetCategories();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, color: "#ccc", fontSize: "24px",height: "75px"  }} // Customize color and size
        onClick={onClick}
      >
        <AiOutlineCaretLeft  />
      </div>
    );
  };
  
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, color: "#ccc", fontSize: "24px",height: "75px" }} // Customize color and size
        onClick={onClick}
      >
        <AiOutlineCaretRight  />
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true, 
    prevArrow: isMobile ? '' : <CustomPrevArrow />,
    nextArrow: isMobile ? '' :  <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section
      style={{
        width: isMobile ? "100vw" : "90vw",
        marginLeft: isMobile ? "0" : "5vw",
        height: "100px",
        padding: isMobile ? 5 : 50,
        backgroundColor:isMobile ?  "black" : '',
        fontSize: "8px"
      }}
    >
      <div className="container-fluid p-0 ">
        <div className="row m-0">
          <Slider {...settings}>
            {Categories &&
              Categories.map((category, i) => (
                <div  key={i}
                onClick={()=>handelClickCategory(category.SCategoryID)}
                >
                  <div
                    className="col-lg-3 col-sm-6 p-0"
                    style={{
                      backgroundImage:
                        category.photo === ""
                          ? ""
                          : `url(${adminUrl}/backend/${category.photo})`,
                      backgroundColor: isMobile ? "" : "black",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      height: "100px", 
                      width: "100%", 
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center", 
                      textAlign: "center",
                      marginLeft: "10px",
                      opacity: "0.7",
                    }}
                  >
                    <h4 className=" ">
                      {category.photo ? "" : category.SCategory}
                    </h4>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
CategoriesSlider.propTypes = {
  handelClickCategory: PropTypes.func.isRequired,
}; 