import PropTypes from "prop-types"; 
import { useState } from "react";
export const ProductDetails = ({ Product }) => {
    const [tab,setTab] = useState('Details')
  return (
    <section className="tab-product m-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <ul
              className="nav nav-tabs nav-material"
              id="top-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className={tab === "Details" ? "nav-link active" : "nav-link "} 
                  onClick={()=>setTab('Details')}
                  id="top-home-tab"
                  data-bs-toggle="tab" 
                  role="tab"
                  aria-selected="true"
                  style={{cursor:'pointer'}}
                >
                  <i className="icofont icofont-ui-home"></i>Details
                </a>
                <div className="material-border"></div>
              </li>
              <li className="nav-item">
                <a
                  className={tab === "Specification" ? "nav-link active" : "nav-link "} 
                  onClick={()=>setTab('Specification')}
                  id="profile-top-tab"
                  data-bs-toggle="tab"
                  style={{cursor:'pointer'}}
                  role="tab"
                  aria-selected="false"
                >
                  <i className="icofont icofont-man-in-glasses"></i>
                  Delivery Information
                </a>
                <div className="material-border"></div>
              </li>
              <li className="nav-item">
                <a
                  className={tab === "Video" ? "nav-link active" : "nav-link "} 
                  onClick={()=>setTab('Video')} 
                  id="contact-top-tab"
                  data-bs-toggle="tab"
                  style={{cursor:'pointer'}}
                  role="tab"
                  aria-selected="false"
                >
                  <i className="icofont icofont-contacts"></i>Video
                </a>
                <div className="material-border"></div>
              </li>
              <li className="nav-item">
                <a
                  className={tab === "Review" ? "nav-link active" : "nav-link "} 
                  onClick={()=>setTab('Review')} 
                  id="review-top-tab"
                  data-bs-toggle="tab" 
                  role="tab"
                  aria-selected="false" 
                  style={{cursor:'pointer'}}
                >
                  <i className="icofont icofont-contacts"></i>Write Review
                </a>
                <div className="material-border"></div>
              </li>
              <li className="nav-item">
                <a
                  className={tab === "OtherDetails" ? "nav-link active" : "nav-link "} 
                  onClick={()=>setTab('OtherDetails')} 
                  id="Other-Details-tab"
                  data-bs-toggle="tab" 
                  role="tab"
                  aria-selected="false"
                  style={{cursor:'pointer'}}
                >
                  <i className="icofont icofont-contacts"></i>Other Details
                </a>
                <div className="material-border"></div>
              </li>
            </ul>
            <div className="tab-content nav-material" id="top-tabContent">
              <div
                className={tab === "Details" ? "tab-pane fade show active" : "tab-pane fade"}
                id="top-home"
                role="tabpanel"
                aria-labelledby="top-home-tab"
              > 
               <div
                    dangerouslySetInnerHTML={{
                      __html: Product.ProductDetails
                        ? Product.ProductDetails.substring(0, 50000)
                        : "",
                    }}
                  />
              </div>
              <div
                className={tab === "Specification" ? "tab-pane fade show active" : "tab-pane fade"}
                id="top-profile"
                role="tabpanel"
                aria-labelledby="profile-top-tab"
              > 
               <div
                    dangerouslySetInnerHTML={{
                      __html: Product.DeliveryInformation
                        ? Product.DeliveryInformation.substring(0, 50000)
                        : "",
                    }}
                  />
              </div>
              <div
                className={tab === "Video" ? "tab-pane fade show active" : "tab-pane fade"}
                id="top-contact"
                role="tabpanel"
                aria-labelledby="contact-top-tab"
              >
                {/* <div className="">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BUWzX78Ye_8"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                </div> */}
              </div>
              <div
                className={tab === "Review" ? "tab-pane fade show active" : "tab-pane fade"}
                id="top-review"
                role="tabpanel"
                aria-labelledby="review-top-tab"
              >
                <form className="theme-form">
                  <div className="form-row row">
                    <div className="col-md-12">
                      <div className="media">
                        <label>Rating</label>
                        <div className="media-body ms-3">
                          <div className="rating three-star">
                            <i className="fa fa-star"></i>{" "}
                            <i className="fa fa-star"></i>{" "}
                            <i className="fa fa-star"></i>{" "}
                            <i className="fa fa-star"></i>{" "}
                            <i className="fa fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your name"
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="review"
                        placeholder="Enter your Review Subjects"
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="review">Review Title</label>
                      <textarea
                        className="form-control"
                        placeholder="Wrire Your Testimonial Here"
                        id="exampleFormControlTextarea1"
                        rows="6"
                        disabled
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-solid" type="submit" disabled>
                        Submit YOur Review
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div
                className={tab === "OtherDetails" ? "tab-pane fade show active" : "tab-pane fade"}
                id="Other-Details"
                role="tabpanel"
                aria-labelledby="Other-Details-tab"
              > 
               <div
                    dangerouslySetInnerHTML={{
                      __html: Product.OtherDetails
                        ? Product.OtherDetails.substring(0, 50000)
                        : "",
                    }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProductDetails.propTypes = {
  Product: PropTypes.object.isRequired,
  Shop: PropTypes.object.isRequired,
};
ProductDetails.defaultProps = {
  Product: {},
  Shop: {},
};
