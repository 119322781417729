//  import './App.css'
import { useEffect } from 'react';
import { ModalCart } from './components/modalCart'
import { GetShopByID } from './data/ShopController'; 
import GetRouter from './router'
import { adminUrl } from './components/urls'; 
import { StikyButtons } from './components/StikyButtons';
 
function App() { 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetShopByID(); 
        document.title = data.ShopName;

        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/svg+xml';
        link.rel = 'icon';
        link.href = adminUrl+'/backend/'+data.Logo; 
        if (!document.querySelector("link[rel*='icon']")) {
          document.head.appendChild(link);
        }
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <GetRouter /> 
      <ModalCart /> 
      <StikyButtons />
    </>
  )
}

export default App
