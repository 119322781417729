import PropTypes from "prop-types";
import { adminUrl } from "./urls";
import { CurrencyConverter } from "./Currency";
import useShopNow from "./ShopNow";
import { useEffect, useRef, useState } from "react";
import { GetProductsInfos } from "../data/productController";
import { CartController } from "../cart/CartController";
import useWindowSize from "./useWindowSize";

export const QuickViewModal = ({ product, onClose }) => {
  //   if (!product) return null;
  const { isMobile } = useWindowSize();
  const [color, setColor] = useState("");
  const [photo, setPhoto] = useState("");
  const [size, setSize] = useState("");
  const [Qty, setQty] = useState(1);
  const [isAdded, setISadded] = useState(false);
  const [ColorSelected, setColorSelected] = useState(false);
  const [ProductInfos, setProductInfos] = useState([]);
  const [Error, setError] = useState("");
  const shopNow = useShopNow();
  const handelAdeedToCart = () => {
    // console.log(ProductInfos);
    // console.log(ProductInfos.length);

    if (ProductInfos.length > 0) {
      const sizeCount = ProductInfos.reduce((count, product) => {
        if (product.Descr && product.Descr.trim() !== "") {
          return count + 1;
        }
        return count;
      }, 0);

      const photoCount = ProductInfos.reduce((count, product) => {
        if (product.Photo && product.Photo.trim() !== "") {
          return count + 1;
        }
        return count;
      }, 0);

      const colorCount = ProductInfos.reduce((count, product) => {
        if (product.color && product.color.trim() !== "") {
          return count + 1;
        }
        return count;
      }, 0);

      if (colorCount > 0 && photoCount > 0 && color === "") {
        setError("Please select a color or photo ...");
        return false;
      }

      if (colorCount > 0 && color === "") {
        setError("Please select a color...");
        return false;
      }

      if (photoCount > 0 && photo === "") {
        setError("Please select a photo...");
        return false;
      }

      if (sizeCount > 0 && size === "") {
        setError("Please select a size...");
        return false;
      }
      // return false;
    }

    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const newItemAdded = {
      ProductID: product.ProductID,
      Price: product.HasDiscount ? product.DiscountPrice : product.Price,
      Image: product.Photo,
      Product: product,
      color: color,
      size: size,
      photo: photo,
      Qty: Qty,
    };
    cart.push(newItemAdded);

    // Stringify and save the updated cart back to localStorage
    localStorage.setItem("cart", JSON.stringify(cart));

    setISadded(true);
    setTimeout(() => {
      setISadded(false);
    }, 5000);

    setColorSelected("");
    setColor("");
    setPhoto("");
    setSize("");
    setQty("1");

    onClose();
  };

  const modalRef = useRef(null);

  // const handleCloseModal = () => {
  // const modalElement = modalRef.current;
  // console.log(modalElement)
  // if (modalElement) {
  //   const modal = new window.bootstrap.Modal(modalElement);
  //   modal.hide();
  //   modalElement.classList.remove('show');
  //   modalElement.style.display = 'none';
  //   modalElement.style.paddingLeft = '';
  //   // Optionally, remove the backdrop manually if needed
  // setTimeout(() => {
  //   const backdropElement = document.querySelector('.modal-backdrop');
  //   if (backdropElement) {
  //     backdropElement.classList.remove('show');
  //     backdropElement.remove();
  //   }
  // }, 300);
  // }

  // };

  useEffect(() => {
    const fetchedProductInfos = async () => {
      try {
        const ProductInfos = await GetProductsInfos(product.ProductID);
        setProductInfos(ProductInfos);
        // console.log(ProductInfos);
      } catch (err) {
        console.error("Failed to fetch products");
        console.log(err);
      } finally {
        // console.log(false);
      }
    };
    fetchedProductInfos();
  }, [product]);

  return (
    <>
      <div
        className={isAdded ? "added-notification show " : "added-notification "}
      >
        <img
          src={adminUrl + "/backend/" + product.Photo}
          className="img-fluid"
          alt=""
        />
        <h3>added to cart</h3>
      </div>
      <div
        className="modal fade bd-example-modal-lg theme-modal show"
        id="quick-view"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        ref={modalRef}
        style={{ 
          display: "block", 
          opacity: 1, 
          width: isMobile ? '100vw' : '100vw',
          position: isMobile ? 'fixed' : 'block',
          left:0,
          top:0
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
            position: "fixed",
            top: 0,
            left: 0,
            opacity: 0.4,
            display: isMobile ? 'none' : 'block'
          }}
          onClick={onClose}
        ></div>
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content quick-view-modal"
          >
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
                 
              >
                <span aria-hidden="true" style={{fontSize:'44px', fontWeight:'bolder'}}>&times;</span>
              </button>
              <div className="row">
                <div className="col-lg-6 col-xs-12">
                  <div className="quick-view-img">
                    <img
                      src={adminUrl + "/backend/" + product.Photo}
                      alt=""
                      className="img-fluid blur-up lazyload"
                    />
                  </div>
                </div>
                <div className="col-lg-6 rtl-text">
                  <div className="product-right">
                    <h2>{product.ProductName}</h2>
                    <h3>
                      {product.HasDiscount
                        ? CurrencyConverter(product.DiscountPrice)
                        : CurrencyConverter(product.Price)}
                    </h3>
                    <ul className="color-variant">
                      {ProductInfos &&
                        ProductInfos.map((ProductInfo, i) => (
                          <li
                            key={i}
                            style={{
                              backgroundImage:
                                ProductInfo.Photo &&
                                `url("${adminUrl}/backend/${product.Photo}")`,
                              backgroundColor: ProductInfo.color
                                ? ProductInfo.color
                                : "",
                              display:
                                ProductInfo.color === "" ?
                                ProductInfo.Photo === ""
                                  ? "none"
                                  : "" : "",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              height: ColorSelected === i ? "50px" : "",
                              width: ColorSelected === i ? "50px" : "",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            onClick={() =>
                              ProductInfo.Photo
                                ? setPhoto(ProductInfo.Photo) +
                                  setError("") +
                                  setColor("") +
                                  setColorSelected(i)
                                : setColor(ProductInfo.color) +
                                  setError("") +
                                  setPhoto("") +
                                  setColorSelected(i)
                            }
                            // className={ColorSelected === i && 'active'}
                          ></li>
                        ))}
                      {/* <li className="bg-light0 "></li>
                  <li className="bg-light1 "></li>
                  <li className="bg-light2"></li> */}
                    </ul>
                    <div className="border-product">
                      <h6 className="product-title">product details</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.ProductDetails
                            ? product.ProductDetails.substring(0, 50000)
                            : "",
                        }}
                      />
                    </div>
                    <div className="product-description border-product">
                      <div className="size-box">
                        <ul>
                          {ProductInfos &&
                            ProductInfos.map((ProductInfo, i) =>
                              ProductInfo.Descr ? (
                                <li
                                  key={i}
                                  style={{
                                    borderRadius: "20%",
                                    width: "auto",
                                    padding: "5%",
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    margin:"3px",
                                  }}
                                  onClick={() =>
                                    setSize(ProductInfo.Descr) + setError("")
                                  }
                                  className={
                                    ProductInfo.Descr === size && "active"
                                  }
                                >
                                  <a>{ProductInfo.Descr}</a>
                                </li>
                              ) : null
                            )}
                        </ul>
                      </div>
                      <h6 className="product-title">quantity</h6>
                      <div className="qty-box">
                        {/* <div className="input-group"><span className="input-group-prepend"><button type="button"
                                                className="btn quantity-left-minus" data-type="minus" data-field=""><i
                                                    className="ti-angle-left"></i></button> </span>
                                        <input type="text" name="quantity" className="form-control input-number"
                                            defaultValue="1" /> <span className="input-group-prepend"><button type="button"
                                                className="btn quantity-right-plus" data-type="plus" data-field=""><i
                                                    className="ti-angle-right"></i></button></span>
                                    </div> */}
                        <CartController setQty={setQty} />
                      </div>
                    </div>
                    <h3>{Error}</h3>
                    <div className="product-buttons">
                      <a
                        href="#"
                        className="btn btn-solid"
                        onClick={handelAdeedToCart}
                        data-bs-dismiss={color === "2" ? "modal" : ""}
                        aria-label="Close"
                      >
                        add to cart
                      </a>{" "}
                      <a
                        href="#"
                        className="btn btn-solid"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => shopNow(product)}
                      >
                        view detail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

QuickViewModal.propTypes = {
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
QuickViewModal.defaultProps = {
  product: [],
  onClose: {},
};
