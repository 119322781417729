import PropTypes from "prop-types";
import { useState } from "react";

export const CartQtyInput = ({ newQty }) => {
  return (
    <input
      type="text"
      name="quantity"
      className="form-control input-number"
      value={newQty}
      readOnly
    />
  );
};

CartQtyInput.propTypes = {
  newQty: PropTypes.number.isRequired,
};

export const CartQtyMinus = ({ onMinus }) => {
  return (
    <button
      type="button"
      className="btn quantity-left-minus"
      data-type="minus"
      data-field=""
      onClick={onMinus}
    >
      <i className="ti-angle-left"></i>
    </button>
  );
};

CartQtyMinus.propTypes = {
  onMinus: PropTypes.func.isRequired,
};

export const CartQtyPlus = ({ onPlus }) => {
  return (
    <button
      type="button"
      className="btn quantity-right-plus"
      data-type="plus"
      data-field=""
      onClick={onPlus}
    >
      <i className="ti-angle-right"></i>
    </button>
  );
};

CartQtyPlus.propTypes = {
  onPlus: PropTypes.func.isRequired,
};

export const CartController = ({ setQty }) => {
    const [newQty, setNewQty] = useState(1);
  
    const handleMinus = () => {
      if (newQty > 1) {
        const updatedQty = newQty - 1;
        setNewQty(updatedQty);
        setQty(updatedQty); // Update parent state
      }
    };
  
    const handlePlus = () => {
      const updatedQty = newQty + 1;
      setNewQty(updatedQty);
      setQty(updatedQty); // Update parent state
    };
  
    return (
      <div className="input-group">
        <span className="input-group-prepend">
          <CartQtyMinus onMinus={handleMinus} />
        </span>
        <CartQtyInput newQty={newQty} />
        <span className="input-group-prepend">
          <CartQtyPlus onPlus={handlePlus} />
        </span>
      </div>
    );
  };
  
  CartController.propTypes = {
    setQty: PropTypes.func.isRequired,
  };